//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background: $color-yellow-light2 !default;
$message-global-note__border-color: $color-yellow-light3 !default;
$message-global-note__color: $text__color !default;

$message-global-note-link__color: $link__color !default;
$message-global-note-link__color-hover: $link__hover__color !default;
$message-global-note-link__color-active: $link__active__color !default;

$message-global-caution__background: $color-red9 !default;
$message-global-caution__border-color: none !default;
$message-global-caution__color: $color-white !default;

$message-global-caution-link__color: $link__color !default;
$message-global-caution-link__color-hover: $link__hover__color !default;
$message-global-caution-link__color-active: $link__active__color !default;

//  Header
$header__background-color: false !default;
$header-icons-color: $color-gray56 !default;
$header-icons-color-hover: $color-gray20 !default;
$customer-welcome__z-index: $dropdown-list__z-index + 1 !default;

$addto-color: $text__color__muted !default;
$addto-hover-color: darken($addto-color, 5%) !default;

$addto-grid-color: $icon-font__color-faded !default;
$addto-grid-hover-color: darken($addto-grid-color, 5%) !default;

$minicart-icons-color: $header-icons-color !default;
$minicart-icons-color-hover: $header-icons-color-hover !default;

$button__shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

$h1__margin-bottom__desktop: $indent__xl !default;

//  Footer
$footer__background-color: false !default;

.cookie-status-message {
    display: none;
}

body {
    @include lib-css(background-color, $page__background-color);
}

//
//  Edge: prevent Visual Search
//  ---------------------------------------------
.fotorama__img,
.fotorama__img--full,
.product-image-photo,
.product-image,
.product-image-wrapper img,
.category-grid__image,
.category-grid-widget__image {
    pointer-events: none;
}

//
//  Header
//  ---------------------------------------------

.page-header {
    @include lib-css(background-color, $header__background-color);
    border-bottom: 1px solid $border-color__base;
    margin-bottom: $indent__base;
}

.header {
    &.content {
        @extend .abs-add-clearfix;
        padding: 20px 10px;
        position: relative;
    }
}

.page-layout-checkout,
.page-print {
    .logo {
        position: initial;
    }

    .header.content {
        padding-bottom: 25px;
    }
}

.logo {
    display: flex;
    align-items: center;
    float: left;
    margin: 0 0 $indent__s $indent__xl;
    padding-top: 10px;
    z-index: 5;

    img {
        display: block;
    }

    .page-print & {
        float: none;
    }
}

.page-main {
    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 10;
        background-color: $color-white;

        .content {
            box-sizing: border-box;
            display: flex;
            max-width: $layout__max-width - $layout-indent__width * 2;
            margin: 0 auto;
            padding: $layout-indent__width;
            border-width: 1px 0 0 0;
            border-style: solid;
            border-color: $border-color__base;

            p {
                flex: 1;
            }
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    @include lib-css(background-color, $footer__background-color);
    margin-top: auto;
}

.footer {
    &.content {
        margin-top: 25px;
        padding-bottom: 25px;
        padding-top: 25px;

        ul {
            @extend .abs-reset-list;
        }

        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }

    .copyright,
    .bugs {
        display: block;
        margin: 20px 0 0;
    }
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding: 0,
                $_dropdown-list-item-padding: 0,
                $_dropdown-toggle-icon-content: $icon-down,
                $_dropdown-toggle-active-icon-content: $icon-up,
                $_icon-font-text-hide: true,
                $_icon-font-margin: 0 0 0 5px,
                $_icon-font-size: 16px,
                $_icon-font-line-height: 22px,
                $_dropdown-list-min-width: 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            display: flex;
            align-items: center;
            font-weight: $font-weight__regular;
        }
    }
}

ul.dropdown.switcher-dropdown {
    z-index: 100000;
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Desktop (Cookie message max-width)
//  _____________________________________________

@include min-screen($layout__max-width - $layout-indent__width * 2) {
    .message.global {
        &.cookie {
            .content {
                border-width: 1px 1px 0 1px;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    body {
        overflow-x: hidden;
    }

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .header {
        &.panel {
            display: flex;
            justify-content: space-between;

            a {
                color: $text__color;
            }

            .topbar__list,
            > .header.links {
                display: none;
                font-size: 0;
                @include lib-list-inline();

                &:first-child > li {
                    margin: 0 15px 0 0;
                }

                &:last-child > li {
                    margin: 0 0 0 15px;
                }

                > li {
                    margin: 0;
                    font-size: $font-size__base;

                    &.welcome,
                    a {
                        line-height: 1.4;
                    }

                    &.welcome {
                        a {
                            @include lib-css(padding-left, $indent__xs);
                        }
                    }
                }
            }
        }

        &.content {
            @extend .abs-add-clearfix-desktop;
        }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;

        .panel.wrapper {
            border-top: 8px solid $brand__primary__color;
        }

        .header.panel {
            padding-bottom: $indent__s;
            padding-top: $indent__s;
        }

        .switcher {
            display: inline-block;
        }
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .customer-welcome {
        @include lib-dropdown(
            $_toggle-selector: '.action.switch',
            $_options-selector: 'ul',
            $_dropdown-actions-padding: 0,
            $_dropdown-list-item-padding: 0,
            $_dropdown-toggle-icon-content: $icon-down,
            $_dropdown-toggle-active-icon-content: $icon-up,
            $_icon-font-text-hide: true,
            $_icon-font-size: 10px,
            $_icon-font-line-height: 22px,
            $_dropdown-list-pointer-position: right,
            $_dropdown-list-position-right: 0,
            $_dropdown-list-z-index: $customer-welcome__z-index
        );

        li {
            a {
                @include lib-link(
                    $_link-color: $color-gray20,
                    $_link-text-decoration: none,
                    $_link-color-visited: $color-gray20,
                    $_link-text-decoration-visited: none,
                    $_link-color-hover: $color-gray20,
                    $_link-text-decoration-hover: none,
                    $_link-color-active: $color-gray20,
                    $_link-text-decoration-active: none
                );
                display: block;
                line-height: 1.4;
                padding: 8px;
            }
        }

        .customer-name {
            cursor: pointer;
        }

        .customer-menu {
            display: none;
        }

        .action.switch {
            @include lib-button-reset();
        }

        .header.links {
            min-width: 175px;
        }

        &.active {
            .action.switch {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }

            .customer-menu {
                display: block;
            }
        }

        .greet {
            display: none;
        }
    }

    .logo {
        margin: 0;
    }

    .footer {
        &.content {
            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }

    .footer-columns {
        display: flex;
    }

    .message.global {
        &.cookie {
            .actions {
                margin-left: $indent__s;
            }
        }
    }

}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .header.content {
        .header-right {
            display: flex;
        }
    }

    .block-search .control {
        border: 0;
        background: white;
    }

    body {
        overflow-x: hidden;
    }

    .top-search__container {
        display: flex;
        align-items: center;
        position: relative;
        margin-right: 30px;
    }

    .search-autocomplete--mobile {
        bottom: -14px;
    }

    .action-skip-wrapper {
        position: relative;
    }

    .message.global {
        &.cookie {
            .content {
                flex-direction: column;
            }

            .actions {
                margin-top: $indent__s;
            }
        }
    }

    .header {
        &.content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            padding: $indent__s 15px;

            &:before, &:after {
                display: none;
            }

            .nav-toggle {
                position: initial;
            }

            .logo {
                margin: 0 auto;
                position: relative;
                max-height: 55px;
                padding-top: 5px;

                img {
                    width: 90px;
                }
            }

            .minicart-wrapper, .block-search {
                margin-top: 0;
            }

            .block-search .label:before {
                margin-right: 0;
            }

            .minicart-wrapper {
                .action.showcart:before {
                    margin-right: 0;
                }
            }

            .form.minisearch.active {
                .control {
                    background-color: $color-white;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    bottom: -63px;
                    z-index: 400;
                }

                .top-search__container {
                    padding: 0 15px;
                    position: relative;
                    border-bottom: 1px solid $border-color__base;

                }
            }
        }
    }
}

.logo {
    float: left;
    margin: 0 0 $indent__s $indent__xl;
    z-index: 5;

    img {
        display: block;
    }

    .page-print & {
        float: none;
    }
}

.page-main {
    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 10;
        background-color: $color-white;

        .content {
            box-sizing: border-box;
            display: flex;
            max-width: $layout__max-width - $layout-indent__width * 2;
            margin: 0 auto;
            padding: $layout-indent__width;
            border-width: 1px 0 0 0;
            border-style: solid;
            border-color: $border-color__base;

            p {
                flex: 1;
            }
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    @include lib-css(background-color, $footer__background-color);
    margin-top: auto;
}

.footer {
    &.content {
        margin-top: 25px;
        padding-bottom: 25px;
        padding-top: 25px;

        ul {
            @extend .abs-reset-list;
        }

        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }

    .copyright,
    .bugs {
        display: block;
        margin: 20px 0 0;
    }
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding: 0,
                $_dropdown-list-item-padding: 0,
                $_dropdown-toggle-icon-content: $icon-down,
                $_dropdown-toggle-active-icon-content: $icon-up,
                $_icon-font-text-hide: true,
                $_icon-font-size: 16px,
                $_icon-font-line-height: 22px,
                $_dropdown-list-min-width: 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Desktop (Cookie message max-width)
//  _____________________________________________

@include min-screen($layout__max-width - $layout-indent__width * 2) {
    .message.global {
        &.cookie {
            .content {
                border-width: 1px 1px 0 1px;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    body {
        overflow-x: hidden;
    }

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .header {
        &.panel {
            display: flex;
            justify-content: space-between;

            a {
                color: $text__color;
            }

            .topbar__list,
            > .header.links {
                font-size: 0;
                @include lib-list-inline();

                &:first-child > li {
                    margin: 0 15px 0 0;
                }

                &:last-child > li {
                    margin: 0 0 0 15px;
                }

                > li {
                    margin: 0;
                    font-size: $font-size__base;

                    &.welcome,
                    a {
                        line-height: 1.4;
                    }

                    &.welcome {
                        a {
                            @include lib-css(padding-left, $indent__xs);
                        }
                    }
                }
            }
        }

        &.content {
            @extend .abs-add-clearfix-desktop;
        }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;

        .panel.wrapper {
            border-top: 8px solid $brand__primary__color;
        }

        .header.panel {
            padding-bottom: $indent__s;
            padding-top: $indent__s;
        }

        .switcher {
            display: inline-block;
        }
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .customer-welcome {
        @include lib-dropdown(
            $_toggle-selector: '.action.switch',
            $_options-selector: 'ul',
            $_dropdown-actions-padding: 0,
            $_dropdown-list-item-padding: 0,
            $_dropdown-toggle-icon-content: $icon-down,
            $_dropdown-toggle-active-icon-content: $icon-up,
            $_icon-font-text-hide: true,
            $_icon-font-size: 10px,
            $_icon-font-line-height: 22px,
            $_dropdown-list-pointer-position: right,
            $_dropdown-list-position-right: 0,
            $_dropdown-list-z-index: $customer-welcome__z-index
        );

        li {
            a {
                @include lib-link(
                    $_link-color: $color-gray20,
                    $_link-text-decoration: none,
                    $_link-color-visited: $color-gray20,
                    $_link-text-decoration-visited: none,
                    $_link-color-hover: $color-gray20,
                    $_link-text-decoration-hover: none,
                    $_link-color-active: $color-gray20,
                    $_link-text-decoration-active: none
                );
                display: block;
                line-height: 1.4;
                padding: 8px;
            }
        }

        .customer-name {
            cursor: pointer;
        }

        .customer-menu {
            display: none;
        }

        .action.switch {
            @include lib-button-reset();
        }

        .header.links {
            min-width: 175px;
        }

        &.active {
            .action.switch {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }

            .customer-menu {
                display: block;
            }
        }

        .greet {
            display: none;
        }
    }

    .logo {
        margin: 0;
    }

    .footer {
        &.content {
            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }

    .footer-columns {
        display: flex;
    }

    .message.global {
        &.cookie {
            .actions {
                margin-left: $indent__s;
            }
        }
    }

}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {

    .block-search .control {
        border: 0;
    }

    body {
        overflow-x: hidden;
    }

    .top-search__container {
        display: flex;
        align-items: center;
    }

    .action-skip-wrapper {
        height: 0;
        position: relative;
    }

    .message.global {
        &.cookie {
            .content {
                flex-direction: column;
            }

            .actions {
                margin-top: $indent__s;
            }
        }
    }

    .header {
        &.content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            padding: $indent__s 15px;

            &:before, &:after {
                display: none;
            }

            .nav-toggle {
                position: initial;
            }

            .logo {
                margin: 0 auto;
                position: relative;

                img {
                    width: 90px;
                }
            }

            .minicart-wrapper, .block-search {
                margin-top: 0;
                margin-right: 5px;
            }

            .block-search .label:before {
                margin-right: 0;
            }

            .minicart-wrapper {
                .action.showcart:before {
                    margin: 0;
                }
            }

            .form.minisearch.active {
                .control {
                    background-color: $color-white;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    bottom: -63px;
                    z-index: 400;
                }

                .top-search__container {
                    padding: 0 15px;
                    border-bottom: 1px solid $border-color__base;
                }
            }
        }
    }
}

.logo {
    float: left;
    margin: 0 0 $indent__s $indent__xl;
    z-index: 5;

    img {
        display: block;

    }

    .page-print & {
        float: none;
    }
}

.page-main {
    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 10;
        background-color: $color-white;

        .content {
            box-sizing: border-box;
            display: flex;
            max-width: $layout__max-width - $layout-indent__width * 2;
            margin: 0 auto;
            padding: $layout-indent__width;
            border-width: 1px 0 0 0;
            border-style: solid;
            border-color: $border-color__base;

            p {
                flex: 1;
            }
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    @include lib-css(background-color, $footer__background-color);
    margin-top: auto;
}

.footer {
    &.content {
        margin-top: 25px;
        padding-bottom: 25px;
        padding-top: 25px;

        ul {
            @extend .abs-reset-list;
        }

        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }

    .copyright,
    .bugs {
        display: block;
        margin: 20px 0 0;
    }
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding: 0,
                $_dropdown-list-item-padding: 0,
                $_dropdown-toggle-icon-content: $icon-down,
                $_dropdown-toggle-active-icon-content: $icon-up,
                $_icon-font-text-hide: true,
                $_icon-font-size: 16px,
                $_icon-font-line-height: 22px,
                $_dropdown-list-min-width: 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
            margin-right: 5px;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Desktop (Cookie message max-width)
//  _____________________________________________

@include min-screen($layout__max-width - $layout-indent__width * 2) {
    .message.global {
        &.cookie {
            .content {
                border-width: 1px 1px 0 1px;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    b.nav-sections-item-switch {
        display: none;
    }

    body {
        overflow-x: hidden;
    }

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .header {
        &.panel {
            display: flex;
            justify-content: space-between;

            a {
                color: $text__color;
            }

            .topbar__list,
            > .header.links {
                font-size: 0;
                @include lib-list-inline();

                &:first-child > li {
                    margin: 0 15px 0 0;
                }

                &:last-child > li {
                    margin: 0 0 0 15px;
                }

                > li {
                    margin: 0;
                    font-size: $font-size__base;

                    &.welcome,
                    a {
                        line-height: 1.4;
                    }

                    &.welcome {
                        a {
                            @include lib-css(padding-left, $indent__xs);
                        }
                    }
                }
            }
        }

        &.content {
            @extend .abs-add-clearfix-desktop;
        }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;

        .panel.wrapper {
            border-top: 8px solid $brand__primary__color;
        }

        .header.panel {
            padding-bottom: $indent__s;
            padding-top: $indent__s;
        }

        .switcher {
            display: inline-block;
        }
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .customer-welcome {
        @include lib-dropdown(
            $_toggle-selector: '.action.switch',
            $_options-selector: 'ul',
            $_dropdown-actions-padding: 0,
            $_dropdown-list-item-padding: 0,
            $_dropdown-toggle-icon-content: $icon-down,
            $_dropdown-toggle-active-icon-content: $icon-up,
            $_icon-font-text-hide: true,
            $_icon-font-size: 10px,
            $_icon-font-line-height: 22px,
            $_dropdown-list-pointer-position: right,
            $_dropdown-list-position-right: 0,
            $_dropdown-list-z-index: $customer-welcome__z-index
        );

        li {
            a {
                @include lib-link(
                    $_link-color: $color-gray20,
                    $_link-text-decoration: none,
                    $_link-color-visited: $color-gray20,
                    $_link-text-decoration-visited: none,
                    $_link-color-hover: $color-gray20,
                    $_link-text-decoration-hover: none,
                    $_link-color-active: $color-gray20,
                    $_link-text-decoration-active: none
                );
                display: block;
                line-height: 1.4;
                padding: 8px;
            }
        }

        .customer-name {
            cursor: pointer;
        }

        .customer-menu {
            display: none;
        }

        .action.switch {
            @include lib-button-reset();
        }

        .header.links {
            min-width: 175px;
        }

        &.active {
            .action.switch {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }

            .customer-menu {
                display: block;
            }
        }

        .greet {
            display: none;
        }
    }

    .logo {
        margin: 0;
    }

    .footer {
        &.content {
            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }

    .footer-columns {
        display: flex;
    }

    .message.global {
        &.cookie {
            .actions {
                margin-left: $indent__s;
            }
        }
    }

}

.show-mobile,
.show-mobile-flex {
    display: none;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .block-search .control {
        border: 0;
    }

    body {
        overflow-x: hidden;
    }

    .top-search__container {
        display: flex;
        align-items: center;
    }

    .cms-home {
        .page-main {
            padding: 0 30px;
        }
    }


    .message.global {
        &.cookie {
            .content {
                flex-direction: column;
            }

            .actions {
                margin-top: $indent__s;
            }
        }
    }

    .header {
        &.content {
            position: relative;
            padding: 3px 15px;

            &:before, &:after {
                display: none;
            }

            .nav-toggle {
                position: initial;
            }

            .logo {
                margin: 0 auto;
                position: relative;

                img {
                    width: 90px;
                }
            }

            .minicart-wrapper, .block-search {
                margin-top: 0;
            }

            .block-search .label:before {
                margin-right: 0;
            }

            .minicart-wrapper {
                .action.showcart:before {
                    margin: 0;
                }
            }

            .form.minisearch.active {
                .control {
                    background-color: $color-white;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    bottom: -63px;
                    z-index: 400;
                }

                .top-search__container {
                    padding: 0 15px;
                    border-bottom: 1px solid $border-color__base;
                }
            }
        }
    }

    .header-wrapper {
        display: flex;
        align-items: center;
    }

    .hamburger {
        display: flex;
        align-items: center;
        flex-direction: column;

        .hamburger-text {
            color: #8f8f8f;
            font-size: 11px;
            font-weight: $font-weight__medium;
        }
    }

    .show-mobile-flex {
        display: flex;
        justify-content: flex-end;

        .header-phone {
            @include lib-icon-font(
                $_icon-font-content: $icon-phone-solid,
                $_icon-font-size: 16px,
                $_icon-font-line-height: 1.1,
                $_icon-font-color: $paragraph-color-light
            );
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            background-color: #efefef;
            border-radius: 4px;
            color: $paragraph-color-light;

            &:hover {
                cursor: pointer;

                &:before {
                    color: #333;
                }
            }
        }
    }

    .show-mobile {
        display: block;

        .my-account {
            @include lib-icon-font(
                $_icon-font-content: $icon-account,
                $_icon-font-size: 16px,
                $_icon-font-line-height: 1.1,
                $_icon-font-color: $paragraph-color-light
            );
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            background-color: #efefef;
            border-radius: 4px;
            color: $paragraph-dark-color;
            margin-right: 5px;

            &:hover {
                cursor: pointer;

                &:before {
                    color: $paragraph-dark-color;
                }
            }
        }
    }
}

.contact-index-index .column:not(.sidebar-main) .form.contact, .column:not(.sidebar-additional) .form.contact {
    width: 100%;
}
