//
//  Variables
//  _____________________________________________

$collapsible-nav-background: $sidebar__background-color;
$collapsible-nav-color: $color-gray34;
$collapsible-nav-current-border: 3px solid transparent;
$collapsible-nav-current-border-color: $brand__primary__color;
$collapsible-nav-current-color: $color-black;
$collapsible-nav-current-font-weight: $font-weight__semibold;
$collapsible-nav-delimiter__border-color: $color-gray82;
$collapsible-nav-item-hover: $color-gray91;

//
//  Collapsible navigation
//  -----------------------------------------

.block-collapsible-nav {
    .content {
        @include lib-css(background, #fff);
        border: 1px solid #edebe6;
    }
    
    .item {
        margin: 0;

        a,
        > strong {
            @include lib-css(color, $collapsible-nav-color);
            display: block;
            padding: 15px 8px;
        }

        a {
            text-decoration: none;
            border-left: 3px solid transparent;

            &:hover {
                background: lighten( $brand__primary__color, 40% );
                border-left: 3px solid $brand__primary__color;

            }
        }

        &.current {
            a,
            > strong {
                background: lighten( $brand__primary__color, 40% );
                color: $brand__primary__color;
            }

            a {
                @include lib-css(border-color, $collapsible-nav-current-border-color);
            }
        }

        .delimiter {
            border-top: 1px solid $collapsible-nav-delimiter__border-color;
            display: block;
            margin: $indent__s 1.8rem;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block-collapsible-nav {
        .title {
            @extend .abs-visually-hidden-desktop;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .block-collapsible-nav {
        left: 0;
        position: absolute;
        top: -21px;
        width: 100%;
        z-index: 5;

        .title {
            @extend .abs-toggling-title-mobile;
            margin-bottom: 0;
        }

        .content {
            border-bottom: $border-width__base solid $border-color__base;
            display: none;

            &.active {
                display: block;
            }
        }
    }
}
