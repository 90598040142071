.home-products-wrapper {
    padding: 60px 0;
    
    h3 {
        text-align: center;
        font-size: 24px;
        font-weight: 300;
        color: $paragraph-color-light;
        margin: 0;
    }
    
    .block-title {
        text-align: center;
        margin-bottom: 60px;
        
        strong {
            font-size: 45px;
            font-weight: 400;
            color: $paragraph-dark-color;
        }
    }
    
    .product-item-actions {
        display: none;
    }
    
    .product-item-name {
        font-size: 22px;
    }
    
    .block.widget .products-grid .product-items {
        justify-content: space-between;
    }
    
    .block {
        margin: 0;
    }
    
    .btn-wrapper {
        display: flex;
        justify-content: center;
    }
    
    .product-item-details {
        position: relative;
        
        .product-reviews-summary {
            display: none;
        }
    }
    
    .product-item .price-box {
        position: absolute;
        top: -265px;
        right: 0;
        height: 75px;
        min-width: 75px;
        margin-bottom: 8px;
        text-align: center;
        padding: 9px;
        background-image: url('../images/pricebg.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        color: #fff;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .special-price,
        .price-final_price {
            font-size: 26px;
            font-weight: 600;
        }
        
        .price-final_price {
            position: relative;
        }
        
        .old-price {
            .special-price,
            .price-final_price {
                font-size: 12px;
            }
        }
        
        .price-label {
            display: none;
        }
        
        .old-price {
            position: absolute;
            top: 12px;
        }
    }
    
}

.page-main {
    .home-products-wrapper {
        .block-category-link {
            display: flex;
            justify-content: center;
        }
    }
}

.block-category-link {
    a {
        height: 45px;
        padding: 0 20px;
        color: $paragraph-color-light;
        font-size: 17px;
        line-height: 43px;
        border: 1px solid #d1d1d1;
        font-weight: 400;
        margin-left: 10px;
        border-radius: 3px;
        
        &:hover {
            color: $paragraph-color-light;
            border: 1px solid $paragraph-color-light;
        }
        
        span {
            @include lib-icon-font(
                $_icon-font-content: $icon-next,
                $_icon-font-size: 9px,
                $_icon-font-color: #c8c8c8,
                $_icon-font-position: after
            );
            
            &::after {
                margin-left: 8px;
                font-weight: 600;
            }
        }
    }
    
    span {
        display: flex !important;
        align-items: baseline;
    }
    
    a:hover {
        text-decoration: none;
        color: $paragraph-color-light;
        border: 1px solid $paragraph-color-light;
    }
}

@include max-screen($screen__l) {
    .home-products-wrapper .product-item .price-box {
        right: 10px;
    }
}

@include max-screen($screen__xl) {
    .home-products-wrapper .product-item .price-box {
        right: 10px;
    }
}

@include max-screen($screen__m) {
    .home-products-wrapper {
        padding: 40px 0;
        
        .product-image-container {
            width: 100px !important;
        }
        
        .block-title {
            strong {
                font-size: 36px;
            }
        }
        
        h3 {
            font-size: 22px;
            margin-bottom: 70px;
        }
        
        .block.widget {
            .product-item-info {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-bottom: 50px;
            }
            
            .products-grid {
                .product-items {
                    text-align: center;
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }
        
        .product-item {
            .price-box {
                position: unset;
                background-image: unset;
                color: $color-blue1;
                height: unset;
                min-width: unset;
                padding: unset;
                margin: unset;
    
                .block-category-link {
                    margin-top: 20px;
                }
    
                span {
                    font-size: 18px;
                }
                
                .old-price {
                    top: 5px;
    
                    span {
                        font-size: 14px;
                    }
                }
            }
    
            .product-item-name {
                display: inline;
            }
        }
        
        .widget .block .block-category-link {
            margin: 0;
        }
    }
}
