//
//  Product Lists
//  _____________________________________________

.page-products {
    .products-grid {
        .product {
            &-items {
                grid-template-columns: auto auto;
            }

            &-item {
                display: flex;
                margin-top: 20px;
                width: 100%;
            }
        }
    }
}

.products {
    margin: $indent__l 0;

    &.products-related,
    &.products-upsell,
    &.products-crosssell {
        .product-items {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }
    }
}

.product {

    .price-box {
        position: relative;

        .old-price {
            position: absolute;
            top: 0;

            span {
                font-size: 15px;
            }
        }
    }

    &-items {
        @extend .abs-reset-list;
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;

        .products-grid & {
            display: inline-block;
            width: 50%;
        }

        &-name {
            @extend .abs-product-link;
            display: block;
            margin: $indent__xs 0;
            word-wrap: break-word;
            hyphens: auto;
            width: 80%;
        }

        &-info {
            width: 100%;
        }

        &-actions {
            .actions-secondary {
                display: none;
                & > .action {
                    @extend .abs-actions-addto;
                    &:before {
                        margin: 0;
                    }

                    span {
                        font-size: 20px;
                    }
                }
            }
            form {
                .action.tocart {
                    line-height: unset;
                    padding: 3px 15px 7px;
                }
            }
        }

        &-description {
            margin: $indent__m 0;
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-box {

            .price {
                font-size: 26px;
                font-weight: 600;
                white-space: nowrap;
            }

            .price-label {
                font-size: $font-size__s;
            }
        }

        .special-price,
        .minimal-price {
            .price {
                font-size: 20px;
                font-weight: $font-weight__bold;
            }

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
        }

        .old-price {
            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .tocompare {
            @include lib-icon-font-symbol($icon-compare-full);
        }

        .tocart {
            white-space: nowrap;
        }
    }
}

.column.main {
    .product {
        &-items {
            margin-left: -$indent__base;
            display: grid;
        }

        &-item {
            padding-left: $indent__base;
        }
    }
}

.price-container {
    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '(' attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            &.item {
                padding-left: 0;
            }

            &-info {
                display: flex;
                //padding: 20px 0;
                border-bottom: 1px solid $border-color__base;
                &:first-child {
                    padding-top: 20px;
                }
            }

            &-photo {
                padding: 0 $indent__l 0;
            }

            &-details {
                flex: 1;
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .abs-product-link, .product-item-name, .product.name a {
        min-height: auto;
    }
}

.category-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &__item {
        width: auto;
        padding-left: 10px;
    }

    &__image-anchor {
        display: block;
    }

    &__anchor {
        font-size: 17px;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .widget-new-grid {
        @include product-carousel();
    }

    .products-list .products .product-item .price-box {
        right: -40px;
    }

    .products {
        .product-item-name {
            margin: 50px 0 5px 0;
        }
    }

    .category-view {
        padding-left: 20px;
    }

    .grid {
        .product-image-photo {
            bottom: auto;
        }

        .product-image-container {
            display: inherit;
        }
    }
}

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }

    .product-reviews-summary,
    .product-item-details [class^=swatch] {
        display: none;
    }
}

@include min-screen($screen__s) {
    .category-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .product {
        &-item {
            .products-grid & {
                margin-bottom: $indent__l;
            }

            &-actions {
                align-items: center;

                .products-grid & {
                    margin: $indent__s 0;
                }

                .actions-primary + .actions-secondary {
                    margin: 10px 0;
                    padding-left: 10px;
                    white-space: nowrap;

                    & > * {
                        white-space: normal;
                    }

                    & > .action:not(:last-child) {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .products-grid .product-item {
        width: 33.3333%;
    }

    .page-products,
    .page-layout-1column,
    .page-layout-3columns,
    .page-products.page-layout-1column,
    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 100%;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .category-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .page-products {
        .products-grid {
            .product {
                &-items {
                    margin-left: -$indent__base;
                    grid-template-columns: auto auto auto;
                }

                &-item {
                    display: flex;
                    width: 100%;
                }
            }
        }
    }

    .page-products.page-layout-1column {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 50%;
            }
        }
    }
}

@include min-screen($screen__l) {
    .page-layout-1column {
        .products-grid {
            .product-item {
                width: (100% / 6);
            }
        }
    }

    .page-layout-3columns {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products {
        .products-grid {
            .product {
                &-items {
                    grid-template-columns: auto auto auto auto;
                    margin: 0;
                }

                &-item {
                    display: flex;
                    width: 100%;
                    margin-left: calc((100% - 4 * 23.233%) / 3);
                    padding: 0;

                    &:nth-child(4n + 1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .page-products {
        &.page-layout-1column {
            .products-grid {
                .product-item {
                    margin-left: 0;
                    width: 20%;
                }
            }
        }

        &.page-layout-3columns {
            .products-grid {
                .product-item {
                    margin-left: 1%;
                    width: 32.667%;

                    &:nth-child(3n) {
                        margin-left: 1%;
                    }

                    &:nth-child(3n + 1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 849px) {
    .catalog-product-view {
        .products-related {
            .product-item {
                .product-image-container {
                    .product-image-wrapper {
                        .product-image-photo {
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}
