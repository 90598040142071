.form {
    .legend {
        font-size: 21px;
        font-weight: $font-weight__bold;
        border-bottom: 1px solid $border-color;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }

    .fields {
        display: flex;

        .field {
            flex-grow: 1;
            width: 50%;
            margin-left: 30px;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}

@include max-screen($screen__m) {
    .form {
        .fields {
            display: block;
            margin: 0 0 20px;

            .field {
                width: 100%;
                margin-left: 0;
            }
        }
    }
}
