.cms-page-view {
    table {
        margin: 20px 0;

        td {
            padding: 0;
        }
    }

    .ves-container {
        margin-bottom: 20px;
    }
}
