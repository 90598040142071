.qty-select {
    display: inline-flex;
    border: 1px solid #e6e6e6;
    border-radius: 4px;

    &__button {
        position: relative;
        width: 45px;
        border: 0;
        background-color: transparent;
        font-size: 9px;
        padding: 0;
        color: #a7a7a7;
        @include lib-button-reset();

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
        }

        &:hover,
        &:focus {
            border: 1px solid #e6e6e6;
        }

        &--increase {
            border-left: 1px solid #e6e6e6;

            @include lib-icon-font(
                $_icon-font-content     : $icon-plus-solid,
                $_icon-font-size        : 10px,
                $_icon-font-text-hide   : true
            );
            &::after {
                width: 2px;
                height: 10px;
            }
        }

        &--decrease {
            border-right: 1px solid #e6e6e6;

            @include lib-icon-font(
                $_icon-font-content     : $icon-minus,
                $_icon-font-size        : 10px,
                $_icon-font-text-hide   : true
            );

        }
    }

    input[type="number"] {
        width: 70px;
        color: #adadad;
        text-align: center;
        border: 0;
    }
}
