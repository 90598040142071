//
//  Variables
//  _____________________________________________

$autocomplete__background-color  : $color-white;
$autocomplete__border            : 1px solid $form-element-input__border-color;
$autocomplete-item__border       : 1px solid $color-gray90;
$autocomplete-item__hover__color : $color-gray91;
$autocomplete-item-amount__color : $color-gray60;


.search-autocomplete {
    position: absolute;
    z-index: 3;
    overflow: hidden;
    @extend .abs-add-box-sizing;
    dl {
        @include lib-list-reset-styles();
        dt {
            &:not(:empty) {
                @include lib-css(background, $autocomplete__background-color);
                @include lib-css(border, $autocomplete__border);
                border-top: 0;
                border-bottom: 0;
            }
            @include lib-css(border-top, $autocomplete-item__border);
            cursor: default;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;
            &:first-child {
                border-top: none;
            }
        }
        dd {
            display: flex;
            align-items: center;
            &:not(:empty) {
                @include lib-css(background, $autocomplete__background-color);
                @include lib-css(border, $autocomplete__border);
                border-top: 0;
            }
            @include lib-css(border-top, $autocomplete-item__border);
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;
            &:first-child {
                border-top: none;
            }
            &:hover,
            &.selected {
                @include lib-css(background, $autocomplete-item__hover__color);
            }
            .amount {
                @include lib-css(color, $autocomplete-item-amount__color);
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }
            .clear {
                clear: both;
            }
        }
    }
    .old-price {
        display: none;
    }
}

#search_autocomplete {
    .product-image-box {
        padding-right: 15px;
    }

    .autocomplete-list-title {
        display: none;
    }
}

.block-search .action.search::before {
    color: $color-white !important;
}
