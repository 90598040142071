.product-attachment {
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
        margin-bottom: 15px;
    }

    &__icon {
        display: flex;
        margin-right: $indent__xs;
    }

    &__anchor {
        display: flex;
        align-items: center;
        margin-right: $indent__xs;
    }
}