a.action.primary,
button {
    @include lib-css(border-radius, $button__border-radius);
}

button {
    &:active {
        @include lib-css(box-shadow, $button__shadow);
    }
}

a.action.primary {
    @include lib-link-as-button();
}

.action.primary {
    @include lib-button-primary();
}

.action.secondary {
    @include lib-button-secondary();
}

.primary {
    line-height: 2;
}

.action.primary {
    span {
        transform: translateY(-1px)
    }
}