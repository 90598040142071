.fotorama__active {
    box-shadow: 0 0 0 transparent;
    opacity: 1 !important;
}

.fotorama__stage__frame {
    &.fotorama__active {
        z-index: 8;
    }
}

.fotorama__wrap--fade {
    .fotorama__stage__frame {
        &.fotorama__active {
            display: block;
            left: 0;
            top: 0;
        }
    }

    .fotorama__fade-rear {
        &.fotorama__active {
            z-index: 9;
        }
    }
}

.fotorama__wrap--only-active {
    .fotorama__stage__frame {
        &.fotorama__active {
            visibility: visible;
        }
    }
}

.gallery-placeholder__image {
    max-width: 550px;
    max-height: 550px;
}

.gallery-placeholder {
    &._block-content-loading {
        .gallery-placeholder__image {
            display: block;
        }
    }

    .gallery-placeholder__image {
        display: none;
    }
}

@include max-screen($screen__m) {
    .gallery-placeholder__image {
        max-height: 250px;
        margin: 0 auto;
        display: block;
    }
}
