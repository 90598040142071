@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/futura-round/bold/FuturaRoundW01-Bold',
    $font-weight: bold,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/futura-round/medium/FuturaRoundW01-Medium',
    $font-weight: normal,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/futura-round/demi/FuturaRoundW01-Demi',
    $font-weight: 500,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/futura-round/book/FuturaRoundW01-Book',
    $font-weight: 300,
    $font-style: normal
);


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}

.cms-page-view {
    .page-main {
        * {
            font-family: Helvetica;
        }
    }
}
