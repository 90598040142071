.social-wrapper {
    display: flex;
    align-items: center;
    padding: 20px 0;
    
    .share-product__title {
        margin-right: .75em;
        font-size: 15px;
        color: $paragraph-color-light;
    }
    
    .social__icon {
        @include lib-icon-font(
            $_icon-font-content: false,
            $_icon-font-size: 15px,
            $_icon-font-display: flex,
            $_icon-font-color: #d5d5d5
        );
    }
    
    .facebook {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-facebook-f-brands
        );
        
        &::before {
            position: relative;
        }
        
    }
    
    .twitter {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-twitter-brands
        );
    }
    
    .pinterest {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-pinterest-p-brands
        );
    }
    
    .mail {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-envelope
        );
    }
    
    .whatsapp {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-whatsapp-brands
        );
        
        &:before {
            font-size: 18px;
            font-weight: 800;
        }
    }
}

.social {
    
    &__icons {
        display: flex;
        align-items: center;
    }
    
    &__icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        margin-right: 10px;
        border-radius: 100%;
        
        &:last-child {
            margin-right: 0;
        }
        
        .pinterest {
            bottom: -4px;
            font-size: 25px;
        }
        
        .mail {
            font-size: 14px;
        }
        
        &:hover {
            text-decoration: none;
            
            i {
                color: darken(#d5d5d5, 10%);
                border: darken(#d5d5d5, 10%);
            }
            
            &.mail {
                font-size: 10px;
            }
        }
    }
    
    &__dark {
        border: 1px solid #d5d5d5;
    }
}
