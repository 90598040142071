.accessories-right {
    .accessory-image-photo {
        margin-top: -10px;
        padding: 0 5px;
    }
    .product {
        vertical-align: top;
    }
    .price-box {
        padding-bottom: 10px;
    }
}
