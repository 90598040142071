.results {
    .product-item .special-price {
        display: flex;
        font-size: 26px;
        font-weight: 600;
    }

    .product-item-inner {
        width: 40%;
    }

    .products-list .product-item-details {
        flex: 1;
    }

    .price-wrapper {
        font-size: 26px;
        font-weight: 600;
    }

    .old-price {
        display: none;
    }

    .price {
        top: 0;
    }

    .products .price {
        top: 0;
        left: 0;
        position: unset;
    }
}

.catalogsearch-result-index {
    .sidebar {
        dl.block {
            margin-bottom: 0;

            .title {
                display: block;
                font-size: 14px;
                color: #333;
            }
        }
    }
}

#layer-product-list {
    .results {
        .price-container {
            position: unset;
        }
    }
}
