.pages {
  @include lib-pager();
  align-items: center;

  .action {
    text-decoration: none;
    &.previous {
      margin-right: 10px;
      font-size: 14px;
      //align-items: center;
      //@include lib-icon-font(
      //                $icon-next,
      //        $_icon-font-size: 12px,
      //        $_icon-font-color: #b2b2b2,
      //        $_icon-font-display: flex
      //);

      //&::before {
      //  transform: rotate(180deg);
      //  font-weight: 600;
      //}

    }

    &.next {
      margin-left: 10px;
      //align-items: center;
      //@include lib-icon-font(
      //                $icon-next,
      //        $_icon-font-size: 12px,
      //        $_icon-font-color: #b2b2b2,
      //        $_icon-font-position: after,
      //        $_icon-font-display: flex
      //);
    }
  }

  .item {
    font-weight: 600;
  }
}
