//
//  Variables
//  _____________________________________________

$minicart__border-color: $color-gray80 !default;
$minicart__padding-horizontal: $indent__base !default;

$minicart-qty__height: 24px !default;

//
//  Minicart
//  ---------------------------------------------

.block-minicart {
    .items-total {
        float: left;
        margin: 0 $indent__s;

        .count {
            font-weight: $font-weight__bold;
        }
    }

    .subtotal {
        margin: 0 $indent__s $indent__s;
        text-align: right;
        font-size: 16px;
        font-weight: 500;

        .label {
            @extend .abs-colon;
        }
    }

    .amount {
        .price-wrapper {
            &:first-child {
                .price {
                    font-size: $font-size__l;
                    font-weight: $font-weight__bold;
                }
            }
        }
    }

    .subtitle {
        display: none;

        &.empty {
            display: block;
            font-size: 14px;
            padding: $indent__l 0 $indent__base;
            text-align: center;
        }
    }

    .text {
        &.empty {
            text-align: center;
        }
    }

    .block-content {
        > .actions {
            margin-top: 15px;
            text-align: center;

            > .primary {
                margin: 0 $indent__s 15px;

                .action {
                    &.primary {
                        @extend .abs-button-l;
                        display: block;
                        margin-bottom: 15px;
                        width: 100%;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .block-category-link,
    .block-product-link,
    .block-cms-link,
    .block-banners {
        margin: 15px 0 0;
        text-align: center;
    }
}

.minicart-wrapper {
    @include lib-dropdown(
        $_toggle-selector: '.action.showcart',
        $_options-selector: '.block-minicart',
        $_dropdown-toggle-icon-content: $icon-shopping-basket-solid,
        $_dropdown-toggle-active-icon-content: $icon-shopping-basket-solid,
        $_dropdown-list-item-padding: false,
        $_dropdown-list-item-hover: false,
        $_icon-font-position: before,
        $_icon-font-size: 22px,
        $_icon-font-line-height: 28px,
        $_icon-font-color: $minicart-icons-color,
        $_icon-font-color-hover: $minicart-icons-color-hover,
        $_icon-font-color-active: $minicart-icons-color
    );
    display: flex;
    align-items: center;
    float: right;

    .cart-text {
        padding-right: 10px;

        .action.showcart.active:before {
            display: none;
        }

        .showcart {
            background: none;
            width: unset;
            height: unset;

            &::before {
                display: none;
            }
        }
    }

    .block-minicart {
        @include lib-css(padding, 25px $minicart__padding-horizontal);
        right: 0;
        width: 320px;
        z-index: 100000;

        &:after {
            left: auto;
            right: 17px;
        }

        &:before {
            left: auto;
            right: 18px;
        }
    }

    .product {
        .actions {
            display: none;
        }
    }

    .action {
        &.close {
            @include lib-button-icon(
                $_icon-font-content: $icon-remove,
                $_icon-font-size: 16px,
                $_icon-font-line-height: 16px,
                $_icon-font-text-hide: true
            );
            @include lib-button-reset();
            height: 40px;
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
        }

        &.showcart {
            white-space: nowrap;

            .counter.qty {
                @include lib-css(background, $brand__primary__color);
                @include lib-css(color, $page__background-color);
                @include lib-css(height, 24px);
                @include lib-css(line-height, 24px);
                border-radius: 99px;
                display: inline-block;
                margin: 3px 0 0;
                min-width: 18px;
                overflow: hidden;
                padding: 0 3px;
                text-align: center;
                white-space: normal;
                position: absolute;
                top: -13px;
                right: -8px;

                &.empty {
                    display: none;
                }

                .loader {
                    > img {
                        @include lib-css(max-width, $minicart-qty__height);
                    }
                }
            }

            .counter-label {
                @extend .abs-visually-hidden;
            }
        }
    }

    .minicart-widgets {
        margin-top: 15px;
    }

    .showcart {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        background-color: #efefef;
        border-radius: 4px;
        color: $paragraph-color-light;
        padding: 0;
    }
}

.minicart-items-wrapper {
    @include lib-css(margin, 0 (-$minicart__padding-horizontal));
    border-left: 0;
    border-right: 0;
    padding: 15px 15px 0 15px;
    overflow: auto;

    .product-item-name {
        min-height: auto;
    }
}

#shipping-new-address-form {
    select {
        text-transform: capitalize;
    }
}

.minicart-items {
    @include lib-list-reset-styles();

    .product-item {
        padding: $indent__base 0;

        &:not(:first-child) {
            @include lib-css(border-top, 1px solid $minicart__border-color);
        }

        > .product {
            @extend .abs-add-clearfix;
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .product-item-pricing {
        .label {
            display: inline-block;
            width: 4.5rem;
        }
    }

    .price-minicart {
        margin-bottom: $indent__xs;
    }

    .product {
        > .product-item-photo,
        > .product-image-container {
            float: left;
        }

        .toggle {
            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 12px,
                $_icon-font-line-height: 12px,
                $_icon-font-text-hide: false,
                $_icon-font-position: after,
                $_icon-font-display: block
            );
            cursor: pointer;
            position: relative;

            &:after {
                @include lib-css(color, $color-gray56);
                position: static;
                margin: 0 0 0 $indent__xs;
            }
        }

        &.active {
            > .toggle {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }
        }
    }

    .product-item-name {
        font-weight: $font-weight__regular;
        margin: 0 0 $indent__s;

        a {
            @include lib-css(color, $link__color);
        }
    }

    .product-item-details {
        padding-left: 88px;

        .price {
            font-weight: $font-weight__bold;
        }

        .price-including-tax,
        .price-excluding-tax {
            margin: $indent__xs 0 0;
        }

        .weee[data-label] {
            @include lib-font-size(11);

            .label {
                @extend .abs-no-display;
            }
        }

        .details-qty {
            display: none;
        }
    }

    .product.options {
        .tooltip.toggle {
            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 28px,
                $_icon-font-line-height: 28px,
                $_icon-font-text-hide: true,
                $_icon-font-margin: -3px 0 0 7px,
                $_icon-font-position: after
            );

            .details {
                display: none;
            }
        }
    }

    .details-qty,
    .price-minicart {
        .label {
            @extend .abs-colon;
        }
    }

    .item-qty {
        margin-right: $indent__s;
        text-align: center;
        width: 40px;
    }

    .update-cart-item {
        @include lib-font-size(11);
        vertical-align: top;
    }

    .subtitle {
        display: none;
    }

    .action {
        &.edit,
        &.delete {
            @include lib-icon-font(
                $_icon-font-content: $icon-edit,
                $_icon-font-size: 18px,
                $_icon-font-line-height: 20px,
                $_icon-font-text-hide: true,
                $_icon-font-color: $minicart-icons-color,
                $_icon-font-color-hover: $primary__color,
                $_icon-font-color-active: $minicart-icons-color
            );
        }

        &.delete {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-trash
            );
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__xs) {
    .minicart-wrapper {
        .block-minicart {
            width: 290px;
        }
    }
}

@include max-screen($screen__s) {
    .minicart-wrapper {
        margin-top: $indent__s;
    }
}

@include max-screen($screen__m) {
    .checkout-index-index {
        .show-mobile {
            display: none;
        }
    }

    .cart-text {
        display: none;
    }

    .minicart-wrapper {
        .block-minicart {
            &:after {
                left: auto;
                right: 1px;
            }

            &:before {
                left: auto;
                right: 2px;
            }
        }

        .showcart {
            width: 35px;
            height: 35px;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .minicart-wrapper {
        .block-minicart {
            right: -15px;
            width: 390px;
        }
    }
}

.minicart-wrapper .action.showcart {
    display: flex;
}
