.footer-columns {
    a {
        color: $paragraph-color-light;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.63;
    }
    
    .parent {
        padding: 0;
        color: $paragraph-dark-color;
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: 600;
    }
}

@include min-screen($screen__m) {
    .footer.content {
        .footer-columns {
            nav {
                flex: 1;
                padding-right: $indent__l;
            }
            
            ul.level0 {
                display: flex;
                justify-content: space-between;
                
                > li {
                    flex: 1;
                }
            }
            
            li.level1 {
                display: block;
                
                &:first-of-type {
                    margin-top: 5px;
                }
            }
            
            li.level0 > a {
                padding: 0;
                color: $paragraph-dark-color;
                font-size: 20px;
                margin-bottom: 10px;
                font-weight: 500;
                line-height: 27px;
            }
        }
    }
}

@include max-screen($screen__m) {
    .footer.content {
        padding-top: 0;
        
        .footer-columns {
            nav {
                margin-bottom: 25px;
            }
            
            ul ul {
                display: block;
                padding: 0 15px;
            }
            
            li.level0 {
                margin-left: -$layout__width-xs-indent;
                margin-right: -$layout__width-xs-indent;
                border-bottom: 1px solid $border-color__base;
                padding-bottom: 15px;
                padding-top: 15px;
                
                &:first-child {
                    border-top: 1px solid $border-color__base;
                }
            }
            
            li.level1 a {
                font-weight: 100;
            }
            
            a.level-top {
                @include lib-icon-font(
                        $icon-next,
                    $_icon-font-position: after,
                    $_icon-font-display: flex
                );
                display: flex;
                justify-content: space-between;
                padding: 0 $layout__width-xs-indent;
                margin-bottom: 15px;
            }
        }
    }
    
    .footer.content .footer-columns a.level-top:after {
        display: none;
    }
}
