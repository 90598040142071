// NOTE: Please excuse the uses of !important, it can't be helped.
@include min-screen($screen__m) {
    .nav-sections {
        .navigation {
            > ul {
                padding: 0;
            }
            
            li.level0 {
                position: static;
                font-weight: 600;
                
                > ul.level0 {
                    position: absolute;
                    right: 0;
                    left: 0 !important;
                    padding: 15px 0;
                    @include abs-add-clearfix;
                }
            }
            
            li.level1 {
                float: left;
                width: (100% / 4); // You can change 4 to the amount of columns you want
            }
            
            li {
                margin-bottom: 0;
            }
            
            .submenu {
                position: static;
                min-width: 100%;
                
                a {
                    display: inline-block;
                    
                    &.ui-state-focus {
                        background-color: transparent;
                    }
                    
                    &:hover {
                        background-color: transparent;
                        text-decoration: underline;
                    }
                }
                
                .submenu {
                    display: block !important;
                    position: static !important;
                    left: 0 !important;
                    box-shadow: none;
                    border: 0;
                    font-weight: $font-weight__regular;
                }
            }
        }
    }
    
    .main__inner-item--parent {
        > a {
            font-weight: $font-weight__medium !important;
            color: $brand__primary__color !important;
        }
    }
}

@include max-screen($screen__m) {
    .all-category {
        display: none;
    }
}

//
//  Debugging
//  ---------------------------------------------
// Use the following to forcefully show a menu
// Replace 12 with the menu #
//
//ul[data-menu=menu-2452] {
//    display: block !important;
//}
