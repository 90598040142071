.banner-grid {
    display: none; // hide it on mobile
}

@include min-screen($screen__m) {
    .banner-grid {
        display: flex;
        margin-bottom: $indent__xl;
        margin-left: -10px;
        margin-right: -10px;

        &__row {
            margin-left: -10px;
            margin-right: -10px;
        }

        &__col {
            padding-left: 10px;
            padding-right: 10px;
        }

        &__flexer {
            display: flex;
            margin-bottom: 20px;
        }

        a,
        img {
            display: block; // removes whitespace
        }

        p {
            margin: 0; // static block adds <p>
        }
    }
}
