.home-usps {
    ul {
        display: flex;
        justify-content: space-between;
        width: 100%;
        list-style: none;
        padding: 0;

        li {
            @include lib-icon-font(
                $_icon-font-content: $icon-success,
                $_icon-font-size: 28px,
                $_icon-font-color: $brand__primary__color
            );
            font-size: 18px;
            color: #151515;
            font-weight: 400;
            line-height: 1;
            align-items: center;
            margin-right: 40px;
            position: relative;
            display: flex !important;
            padding-left: 40px;


            &::before {
                position: absolute;
                left: 0;
            }
        }

    }

}

.checkout-usps {
    margin-top: 20px;
    background: #f5f5f5;
    padding: 15px;

    span {
        font-weight: 500;
    }

    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        padding: 0;
        margin: 10px 0 0 0;

        li {
            @include lib-icon-font(
                $_icon-font-content: $icon-success,
                $_icon-font-size: 28px,
                $_icon-font-color: $brand__primary__color
            );
            display: flex !important;
            align-items: center;
            position: relative;
            font-size: 18px;
            color: $paragraph-dark-color;
            font-weight: 400;
            line-height: 1;
            margin-bottom: 0;
            margin-right: 10px;
            padding-left: 40px;
            padding-bottom: 20px;
            width: 40%;


            &::before {
                position: absolute;
                left: 0;
            }
        }
    }
}

@include max-screen($screen__m) {
    .home-usps {
        display: block;
        padding: 30px 0;

        &:after {
            content: '';
            width: 100%;
            height: 1px;
            background: #e6e6e6;
        }

        ul {
            position: relative;
            display: block;

            &:after {
                content: '';
                width: 100vw;
                height: 1px;
                background: #e6e6e6;
                position: absolute;
                left: -30px;
                bottom: -30px;
            }

            li {
                font-size: 15px;
                line-height: 1.2;
                color: $paragraph-dark-color;
                padding-left: 26px;
                margin-bottom: 10px;

                &::before {
                    font-size: 17px;
                }
            }
        }

        .usp-wrapper__item {
            display: none;
        }
    }

    .checkout-usps {
        ul {
            li {
                width: 100%;
            }
        }
    }
}
