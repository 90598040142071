//
//  Rating variables
//  _____________________________________________

$rating-icon__count          : 5 !default;
$rating-icon__content        : $icon-star !default;
$rating-icon__font           : $icon-font !default;
$rating-icon__font-size      : 16px !default;
$rating-icon__letter-spacing : 2px !default;
$rating-icon__color          : $color-gray78 !default;

$rating-icon__active__color  : $brand__secondary__color !default;

$rating-label__hide          : false !default;
