.fullwidth-wrapper {
    background-image: url('../images/home-fullwidth-img.png');
    background-position: center;
    height: 266px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    
    h2 {
        color: $color-white;
        font-weight: 400;
        font-size: 36px;
        text-align: center;
        padding-top: 28px;
        margin: 0;
    }
}

@include max-screen($screen__m) {
    .fullwidth-wrapper {
        h2 {
            font-size: 22px;
        }
    }
}