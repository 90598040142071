.top-footer-wrapper {
    text-align: center;
    
    &__payment-icons {
        padding: 60px 0 20px 0;
    }
    
    &__copyright {
        color: $paragraph-color-light;
        font-size: 14px;
        text-align: center;
        padding-bottom: 80px;
    }
    
    span {
        margin-left: 3px;
    }
    
    a {
        color: $paragraph-color-light;
        margin-left: 3px;
    }
}

.scrollToTop {
    @include lib-icon-font(
        $_icon-font-content: $icon-arrow-up,
        $_icon-font-size: 26px,
        $_icon-font-line-height: 1.1,
        $_icon-font-color: $brand__primary__color
    );
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 50;
    background: $color-white;
    border-radius: 100%;
    padding: 5px;
}

@include max-screen($screen__m) {
    .top-footer-wrapper__payment-icons {
        padding: 20px 0 20px 0;
    }
}