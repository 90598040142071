.cms-index-index,
.catalog-category-view,
.catalog-product-view {
    .page-wrapper {
        padding-top: 160px;
    }
}

@include max-screen($screen__m) {
    .cms-index-index,
    .catalog-category-view,
    .catalog-product-view {
        .page-wrapper {
            padding-top: 151px;
        }
    }
}

.page-header {
    .panel {
        .switcher {
            .options {
                .action.toggle {
                    display: flex;

                    &:after {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.top-links-wrapper {
    ul {
        display: flex;
        justify-content: flex-end;
        padding-top: 10px;
        margin: 0;
        padding-left: 0;

        .authorization-link {
            display: flex;
            align-items: center;
        }

        .contact {
            @include lib-icon-font(
                $_icon-font-content: $icon-envelope,
                $_icon-font-size: 16px,
                $_icon-font-line-height: 1.1,
                $_icon-font-margin: 0 5px 0 0,
                $_icon-font-color: $paragraph-color-light
            );
        }

        .my-account {
            @include lib-icon-font(
                $_icon-font-content: $icon-account,
                $_icon-font-size: 16px,
                $_icon-font-line-height: 1.1,
                $_icon-font-margin: 0 5px 0 0,
                $_icon-font-color: $paragraph-color-light
            );
        }

        .mobile {
            @include lib-icon-font(
                $_icon-font-content: $icon-phone-solid,
                $_icon-font-size: 16px,
                $_icon-font-line-height: 1.1,
                $_icon-font-margin: 0 5px 0 0,
                $_icon-font-color: $paragraph-color-light
            );
        }

        li {
            list-style-type: none;
            font-size: 16px;
            display: none;
            margin: 0;
            padding-left: 15px;
            color: $paragraph-color-light;

            a {
                color: $paragraph-color-light;
            }
        }
    }
}

.legend_store_german,
.legend_store_dutch,
.legend_store_english,
.legend_store_english_global,
.legend_store_french,
.legend_store_french_global,
.legend_store_spanish,
.legend_store_spanish_global,
.legend_store_swedish,
.legend_store_portuguese,
.legend_store_brazil,
.legend_store_italian {
    .logo img {
        max-width: 120px;
    }
}

.hamburger {
    p {
        position: absolute;
    }
}

.nav-toggle:before {
    font-size: 31px;
}

.header-wrapper {
    &__inner {
        display: flex;
        flex-flow: column;
        align-items: flex-end;
    }

    &__top {
        display: flex;
    }
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.ui-dropdown-list {
    position: relative;
}

p.ui-dropdown-list-trigger {
    margin-top: 0;
}

.ui-dropdown-list-trigger strong {
    position: relative;
    z-index: 999;
}

.ui-dropdown-list-trigger strong:after {
    display: block;
    position: absolute;
    content: '';
    right: 5px;
    top: 10px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #012D6B;
}

.ui-dropdown-list-trigger strong,
.ui-dropdown-list ul a {
    display: block;
    width: 60px;
    cursor: pointer;
    padding: 6px 8px;
    font-size: 13px;
    line-height: 13px;
    color: black;
    font-weight: 200;
    border: 1px solid darken(white, 2%);
}

.ui-dropdown-list ul {
    display: none;
    position: absolute;
    right: 0;
    top: 25px;
    margin-top: 0;
    padding: 0;
    z-index: 1000;
}

.ui-dropdown-list li {
    list-style-type: none;
}

.ui-dropdown-list ul a,
.ui-dropdown-list ul a:link,
.ui-dropdown-list ul a:visited {
    position: relative;
    display: block;
    float: right;
    background-color: darken(white, 2%);
    border-top: 1px solid darken(white, 10%);
}

.ui-dropdown-list ul a:hover,
.ui-dropdown-list ul a:focus {
    background-color: darken($color-white, 3%);
    color: black;
    text-decoration: none;
}

/* JS interactivity */
.no-js .ui-dropdown-list ul,
.ui-dropdown-list.active ul {
    display: block;
}

.ui-dropdown-list.active strong {
    background-color: $color-white;
    color: black;
}

#lang-switcher strong {
    float: right;
}

//
// Sticky header styling
//
@include min-screen($screen__m) {
    .cms-index-index,
    .catalog-product-view,
    .catalog-category-view {
        .page-header {
            position: fixed;
            background: $color-white;
            width: 100%;
            z-index: 10;
            top: 0;
        }
    }
}

@include max-screen($screen__m) {
    .header.content {
        flex-wrap: wrap;
        padding-bottom: 0;

        .header__search-cart {
            display: block;
            margin-top: 5px;
        }
    }

    .header-wrapper__inner {
        display: block;
        width: 100%;

        .header-wrapper__top {
            display: block;
        }

        .top-links-wrapper {
            display: none;
        }

        .block-search {
            margin-right: 0;

            .label {
                display: none;
            }

            .control {
                margin: 0;
                padding: 0;
            }

            input {
                margin: 0;
                height: 50px;
            }

            .minisearch {
                display: flex;
                width: 100%;
                background: #f9f9f9;
                padding: 15px;
                margin-left: -15px;
                margin-right: -15px;

                .search {
                    padding-top: 0;
                    width: 100%;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }

    .filter-active {
        .catalog-category-view {
            .page-wrapper {
                .page-header {
                    z-index: 1;
                }
            }

            .field.search {
                z-index: 1;
            }
        }
    }

    .page-wrapper {
        .page-header {
            margin-bottom: 0;
        }
    }

    #lang-switcher {
        display: none;
    }

    .legendurn_global {
        .panel.header {
            padding-top: 10px;
        }

        .switcher {
            display: inline-block !important;
        }
    }

    .legendurn_global.checkout-cart-index {
        .field.search {
            padding-top: 0;
        }
    }

    .legendurn_global.cms-page-view {
        .field.search {
            padding-top: 0;
        }
    }

    .legendurn_global.customer-account-login {
        .field.search {
            padding-top: 0;
        }
    }

    .field.search {
        padding-top: 30px;
    }

    .cms-index-index,
    .catalog-category-view,
    .catalog-product-view {
        .page-wrapper {
            .page-header {
                position: fixed;
                width: 100%;
                background: white;
                margin-bottom: 0 !important;
                border-bottom: 0;
                z-index: 10;
                top: 0;
            }
        }
    }

    .page-products .columns {
        padding-top: 30px;
    }

    .legend_store_german,
    .legend_store_dutch,
    .legend_store_english,
    .legend_store_english_global,
    .legend_store_french,
    .legend_store_french_global,
    .legend_store_spanish,
    .legend_store_spanish_global,
    .legend_store_swedish,
    .legend_store_portuguese,
    .legend_store_brazil,
    .legend_store_italian {
        .logo img {
            max-width: 60px;
        }

        #switcher-language {
            display: none;
        }
    }
}

@include max-screen($screen__l) {
    .top-links-wrapper {
        ul {
            flex-direction: column;
            padding-top: 0;
        }
    }
}

@media only screen and (max-width: 1150px) and (min-width: 768px) {
    .top-links-wrapper ul {
        flex-direction: column;
    }
}

@media only screen and (max-width: 1080px) {
    .page-wrapper .nav-sections .navigation > ul {
        margin-top: 35px;
    }
}

@media only screen and (max-width: 1150px) {
    .top-links-wrapper ul {
        padding: 0;
    }
}

@media only screen and (max-width: 1150px) and (min-width: 850px) {
    .cms-index-index,
    .catalog-category-view,
    .catalog-product-view {
        .page-wrapper {
            padding-top: 214px;
        }
    }
}

@media only screen and (max-width: 1080px) and (min-width: 850px) {
    .cms-index-index,
    .catalog-category-view,
    .catalog-product-view {
        .page-wrapper {
            padding-top: 249px;
        }
    }
}
