.product.data.items {
    @include lib-data-accordion();
    margin-bottom: $indent__base;
    text-align: center; // Centering tabs
    
    & > .item.title.active > .switch {
        &::before,
        &::after {
            content: '';
            position: absolute;
            right: 100%;
            top: 100%;
            height: 1px;
            width: 100vw;
            background: $border-color__base;
            
        }
        
        &::before {
            right: 100%;
        }
        
        &::after {
            left: 100%;
        }
    }
}

.product.data.items > .item.content {
    text-align: left;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product.data.items {
        @include lib-data-tabs(
            $_tab-content-border-top-status: true
        );
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .product.data.items {
        @include lib-data-accordion();
        .data.item {
            display: block;
        }
    
        & > .item.title.active > .switch {
                     &::before,
                     &::after {
                         width: 0;
                     }
                 }
        
        }
    
    .product.data.items > .item.title > .switch {
        border-bottom: 1px solid $border-color;
    }
}
