.home-categorie-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 40px 0;
    flex-wrap: wrap;
    
    .home-cat--item {
        width: 25%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        &:not(:last-child) {
            padding-right: 10px;
        }
        
        h2 {
            margin-bottom: 5px;
            margin-top: 10px;
        }
        
        .category-link {
            @extend .cta-link
        }
    }
}

.category-grid-widget {
    &__anchor {
        @include lib-link(
            $_link-color: $text__color,
            $_link-color-visited: $text__color,
            $_link-color-hover: $text__color,
            $_link-color-active: $text__color
        );
        font-weight: $font-weight__medium;
    }
}

.featured-categories {
    display: none;
}

@include max-screen($screen__m) {
    .home-categorie-wrapper {
        text-align: center;
        flex-direction: row;
        margin: 0;
        
        h2 {
            font-size: 20px;
        }
        
        .home-cat--item {
            width: 50%;
        }
    }
    
    .featured-categories {
        display: block;
        
        .categorie {
            display: flex;
            align-items: center;
            background: #fbfbfb;
            padding: 10px;
            margin-bottom: 10px;
            border-radius: 5px;
            box-shadow: 0px 1px 5px -2px rgba(0, 0, 0, 0.55);
            background-position: center right 5%;
            background-size: contain;
            background-repeat: no-repeat;
            
            &:hover {
                background-color: #e8edf1;
            }
            
            &:last-child {
                margin: 0;
            }
            
            h3 {
                color: #095a70;
                @include lib-button-icon(
                    $_icon-font-content: $icon-up,
                    $_icon-font-size: 10px
                );
                
                &::before {
                    transform: rotate(90deg);
                    margin-right: 15px;
                }
            }
        }
        
        .all-categories {
            h3 {
                margin: 30px 0;
            }
        }
    }
    
    .swiper-wrapper {
        .category-grid-widget {
            &__anchor {
                word-break: break-all;
                font-size: 23px;
                line-height: 23px;
            }
            
            &__item {
                width: 45% !important;
            }
        }
    }
}
