.products {
    form {
        display: flex;
        align-items: center;

        a {
            padding-right: 20px;
            font-size: 14px;
            color: $paragraph-dark-color;

            &:hover {
                color: $paragraph-dark-color;
            }
        }
    }

    .product-reviews-summary {
        display: none;
    }

    .product-item-details {
        position: relative;
    }

    .price-label {
        display: none;
    }

    .price {
        text-align: center;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .size, .color {
        display: none;
    }

    .product-item .price-box {
        span {
            font-size: 20px;
            font-weight: 600;
            color: $brand__primary__color;
        }

        .old-price {
            span {
                font-size: 12px;
            }
        }
    }
}

.category-view {
    ul {
        display: flex;

        li {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 25%;

            a {
                font-size: 27px;
            }
        }
    }
}

.category-description-wrapper {
    max-width: $layout__max-width;
    margin: 0 auto;
    padding: 0 $layout-indent__width;

    h1 {
        font-size: 27px;
        line-height: 1.3;
        width: 160%;
        padding-right: 50px;
        font-weight: 500;
    }

    p {
        color: $paragraph-color-light;
        font-size: 18px;
        font-weight: 300;

        a {
            color: $brand__primary__color;
        }
    }
}

.category-top-wrapper {
    &__cat-title {
        display: flex;
        margin-bottom: 25px;
        font-weight: 300;
        color: $paragraph-color-light;
        font-size: 34px;

        strong {
            font-weight: 500;
            color: $paragraph-dark-color;
            margin-left: 8px;
        }
    }
}

.category-view {
    .category-image {
        display: none;
    }

    #page-title-heading {
        display: none;
    }

}

.toolbar-sorter {
    #sorter {
        padding-bottom: 5px;
        border-radius: 3px;
        line-height: 1;
        color: #b8b8b8;
        font-weight: 400;
    }
}

.last-toolbar {
    .bottom-toolbar {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 10px;

        .print-wrapper {
            display: none;
        }
    }

    .modes {
        display: none;
    }

    .toolbar-sorter.sorter{
        display: none;
    }

    .toolbar-products .pages {
        display: block;
    }

    .toolbar .pages {
        margin-bottom: 0;
    }
}

#layer-product-list {
    .toolbar {
        #limiter {
            padding-top: 5px;
            padding-bottom: 5px;
            border-radius: 3px;
            line-height: 1;
            color: #b8b8b8;
            font-weight: 400;
        }

        select {
            color: #b8b8b8;
            height: 35px;

        }
    }

    .sorter-action {
        display: none;
    }

    .sorter-label {
        display: none;
    }
}

.toolbar {
    select {
        box-shadow: none !important;
    }
}

.page-products {
    .sorter {
        color: #b8b8b8;
    }

    .columns {
        padding-top: 20px;
    }
}

@include max-screen($screen__m) {
    .cat-customer-score-wrapper span {
        padding: 0 10px;
    }

    .page-products .sorter {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
    }

    .toolbar {
        select {
            padding-right: 0;
        }
    }

    .last-toolbar {
        .bottom-toolbar {
            justify-content: space-around;
        }
    }

    .products {
        .price {
            min-width: 40px;
            height: 40px;
        }

        .product-item {
            .price-box {
                position: unset;
                background: 0;
                color: $primary-color;
                height: auto;
                min-width: unset;
                display: block;
                text-align: unset;
                padding: 0;

                .old-price {
                    span {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .catalog-category-view {
        .product-item-link {
            font-size: 16px;
        }

        .abs-product-link, .product-item-name, .product.name a {
            min-height: 32px;
        }

        .filter.block {
            margin-top: 20px;
        }
    }

    .category-description-wrapper {
        padding: 0 $layout__width-xs-indent;

        .category-description {
            h1 {
                width: auto;
                font-size: 17px;
                padding: 0;
            }

            span {
                font-size: 12px;
            }
        }
    }

    .category-top-wrapper__cat-title {
        font-size: 24px;
        display: none;
    }

    .catalog-category-view {
        .product-item-info {
            position: relative;
        }

        .action.secondary {
            background: $primary-color;
        }

        .more-info {
            padding: 5px 15px;
            font-size: 1.4rem;
            color: $color-white;
            background: $brand__primary__color;
            display: flex;
            align-items: center;
            margin-right: 5px;
            border-radius: 3px;
        }

        .product-item-name {
            min-height: 60px;
        }
    }

    .products .product-reviews-summary {
        display: block;
        margin: 0;
    }
}

@include max-screen($screen__s) {
    .catalog-category-view {
        .product-image-wrapper {
            position: unset;
        }
    }
}

@keyframes reveal {
    from {
        transform: scale(0.001);
    }
    to {
        transform: scale(1);
    }
}

@keyframes slide {
    to {
        transform: translateX(1.5em)
    }
}

.mst-scroll__loader {
    font-size: 20px;
    position: relative;
    width: 4em;
    height: 1em;
    margin: 10px auto;

    .loader-dot {
        display: block;
        width: 1em;
        height: 1em;
        border-radius: 0.5em;
        background: #555;
        position: absolute;
        animation-duration: 0.5s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;

        &:nth-child(1),
        &:nth-child(2) {
            left: 0;
        }

        &:nth-child(3) {
            left: 1.5em
        }

        &:nth-child(4) {
            left: 3em;
        }

        &:nth-child(1) {
            animation-name: reveal;
        }

        &:nth-child(2),
        &:nth-child(3) {
            animation-name: slide;
        }

        &:nth-child(4) {
            animation-name: reveal;
            animation-direction: reverse;
        }
    }
}
