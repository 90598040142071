//
//  Variables
//  _____________________________________________

$checkout-modal-popup__width: 800px !default;

.checkout-index-index {
    .modal-popup {
        .field-tooltip {
            .field-tooltip-content {
                @extend .abs-checkout-tooltip-content-position-top;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-index-index {
        .modal-popup {
            .form-shipping-address {
                padding: 40px;
            }

            .modal-footer {
                display: flex;
                align-items: center;
                justify-content: space-between;;

                .action-save-address {
                    float: right;
                    margin: 0 40px;
                }

                .action-hide-popup {
                    margin-right: 40px;
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    .checkout-index-index {
        .modal-popup {
            .modal-inner-wrap {
                @include lib-css(margin-left, -($checkout-modal-popup__width/2));
                @include lib-css(width, $checkout-modal-popup__width);
                left: 50%;
            }
        }
    }
}
