.engrave {
    &-container {
        background: 0;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        padding: 20px;
        margin-bottom: 20px;

        h3 {
            margin-top: 0;
        }
    }

    &-form {
        display: none;
    }

    &-option-label {
        display: block;
        clear: both;
    }
}
