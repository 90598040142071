.footer-last-column {
    .footer-logo {
        margin-bottom: 20px;
    }

    .trustpilot {
        margin-bottom: 15px;

        .trustpilot-widget {
            iframe {
                .light {
                    .tp-widget-wrapper {
                        text-align: left;
                    }
                }
            }
        }
    }

    .trustpilot-link {
        display: flex;
        align-items: center;

        a {
            font-weight: 200;
            color: $paragraph-color-light;
        }

        &:before {
            margin-right: 5px;

        }
    }
}

.footer-social-media {
    margin: 15px 0;

    .facebook {
        padding-right: 5px;
        @include lib-icon-font(
            $_icon-font-content: $icon-facebook-f-brands,
            $_icon-font-size: 13px,
            $_icon-font-line-height: 1.1,
            $_icon-font-margin: 0 5px 0 0,
            $_icon-font-position: before
        );
    }

    .twitter {
        @include lib-icon-font(
            $_icon-font-content: $icon-twitter-brands,
            $_icon-font-size: 13px,
            $_icon-font-line-height: 1.1,
            $_icon-font-margin: 0 5px 0 0,
            $_icon-font-position: before
        );
    }
}

.icon-wrapper {
    display: flex;
    margin-top: 5px;
}

@include max-screen($screen__m) {
    .footer-last-column {
        .trustpilot-link {
            a {
                font-size: 15px;
            }
        }

        .footer-last-col-top-wrapper {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            .footer-logo {
                width: 35%;
            }
        }
    }

    .footer-social-media {
        display: none;
    }
}
