.box-tocart {
    .tocart {
        background: $brand__primary__color;
        border: 0;
        color: $color-white;
        padding-left: 25px;
        padding-right: 25px;
    }
}

.tax-notice {
    display: none;
}

.product-info-price {
    display: flex;

    .price {
        padding-right: 10px;
        font-weight: 500;
    }

    .price-container {
        display: flex;
        align-items: flex-end;

        .tax-notice {
            display: block;
            color: $paragraph-color-light;
            font-size: 16px;
            line-height: 1;
        }
    }

    .price-extra {
        display: flex;
        flex-direction: column;
        line-height: 0.7;

        span {
            font-size: 16px;
            color: $paragraph-color-light;
        }
    }
}

.product-social-links {
    .product-image {
        display: none;
    }
}

.detailed {
    #video {
        text-align: center;

        #product-video-tab {
            iframe {
                max-width: 100%;
            }
        }
    }

    .short-desc-attributes-wrapper {
        width: 1170px;
        margin: 0 auto;

        h4 {
            display: none;
        }

        .description {
            display: flex;
            align-items: center;
        }

        .product-image {
            width: 20%;
        }

        h1 {
            margin-bottom: 10px;
        }

        h2 {
            font-size: 24px;
            color: $paragraph-color-light;
            font-weight: 300;
            margin: 0;
            padding-bottom: 20px;
        }

        p {
            color: $paragraph-color-light;
            font-size: 17px;
            font-weight: 100;
            line-height: 1.5;
        }

    }
}

.attribute-wrapper {
    display: flex;

    &__attribute {
        display: flex;
        align-items: center;
        font-weight: 400;
        color: $paragraph-color-light;
        font-size: 16px;
        padding: 5px 0;
        min-width: 200px;
    }

    &__content {
        color: $paragraph-color-light;
        font-weight: 300;
        font-size: 16px;
        padding: 5px 0;
    }
}

.product-info-main {
    h4 {
        font-weight: 400;
        font-size: 22px;
    }

    p {
        color: $paragraph-dark-color;
        font-size: 16px;
        padding-right: 10px;
    }

    .page-title {
        font-size: 32px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .product-usps {
        ul {
            display: flex;

            li {
                padding-right: 10px;
                color: $paragraph-color-light;
                font-size: 16px;
                font-weight: 100;

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }

    table > thead > tr > th, table > thead > tr > td, table > tbody > tr > th, table > tbody > tr > td, table > tfoot > tr > th, table > tfoot > tr > td {
        padding-left: 0;
    }

    .pricing {
        display: none;
    }
}

.stock {
    display: none;
}

.box-tocart {
    .towishlist {
        height: 45px;
        color: $paragraph-color-light;
        font-size: 17px;
        line-height: 43px;
        border: 1px solid #d1d1d1;
        font-weight: 400;
        margin-top: 10px;
        border-radius: 3px;
        text-align: center;
        width: 100%;

        @include lib-icon-font(
            $_icon-font-content: $icon-wishlist-full,
            $_icon-font-size: 14px,
            $_icon-font-margin: 0 $indent__xs 0 0,
            $_icon-font-vertical-align: top
        );

        &:hover {
            color: $paragraph-color-light;
            border: 1px solid $paragraph-color-light;
        }
    }
}

.product-addto-links {
    font-weight: 300;

    h3 {
        display: none;
    }
}

.klantenservice-banner {
    margin-bottom: 20px;
    background: #eef3f5;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content {
        padding: 15px 25px;
    }

    p {
        font-size: 17px;
        line-height: 1.3;
        font-weight: 400;
    }

    img {
        margin-top: -15px;
        margin-right: 10px;
        align-self: flex-end;
    }
}

.product-banner {
    background: 0;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .content {
        padding: 15px 25px;
    }

    img {
        margin-right: 20px;
    }

    p {
        margin-bottom: 15px;
        font-weight: 400;
        line-height: 1.1;
    }

    .link {
        display: inline-block;
        margin-top: 10px;
    }

    a {
        position: relative;
        font-size: 18px;
        font-weight: 400;
        padding-left: 20px;

        @include lib-icon-font(
            $_icon-font-content: $icon-next,
            $_icon-font-size: 14px,
            $_icon-font-vertical-align: top
        );

        &:before {
            position: absolute;
            left: 0;
            top: 5px;
        }
    }

    a {
        color: $brand__primary__color;
    }
}

.open-product-video {
    span {
        color: $brand__primary__color;
        font-size: 17px;
        display: flex;
        align-items: center;

        @include lib-icon-font(
            $_icon-font-content: $icon-youtube-brands,
            $_icon-font-size: 20px
        );

        &:before {
            padding-right: 10px;
        }
    }
}

@media only screen and (max-width: 1025px) and (min-width: 768px) {

    .detailed .short-desc-attributes-wrapper {
        width: 100%;
    }
}

@include min-screen($screen__l) {
    .detailed .short-desc-attributes-wrapper {
        width: 1170px;
        max-width: 100%;
    }

    .addtocart {
        margin-right: 10px;
    }

}

@include max-screen($screen__m) {
    .product media {
        .action-skip-wrapper {
            height: auto !important;
        }
    }

    .detailed .short-desc-attributes-wrapper {
        width: 100%;
    }

    .product-banner {
        a {
            display: flex;
            align-items: center;
        }

        img {
            display: none;
        }
    }

    .product-addto-links {
        display: block;
    }

    .short-desc-attributes-wrapper {
        width: 100%;
    }

    .detailed .short-desc-attributes-wrapper .description {
        display: block;
    }

    .detailed .short-desc-attributes-wrapper .product-image {
        width: 30%;
    }

    .product-info-main {
        .box-tocart {
            .towishlist {
                box-sizing: border-box;
            }

            .field.qty {
                margin: 0;
            }

            .actions {
                .action.tocart {
                    margin-top: 10px;
                }
            }
        }

        .product-usps {
            margin: 0;

            ul {
                flex-direction: column;

                li {
                    padding-left: 25px;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}
