.engrave-preview {
    display: none;
    margin: 2em 0;
    background-color: #eef3f5;
    padding: 20px;
    text-align: center;

    &.loaded {
        display: block;
    }

    .title {
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 0.5em;
    }

    .preview {
        position: relative;
        display: inline-block;

        .row {
            position: absolute;
            width: 100%;
            text-align: center;
            display: flex;
            top: 0;
            left: 12px;
            justify-content: center;
            align-items: center;
            height: 26px;

            &:last-child {
                top: 22px;
            }

            .day-of-birth,
            .day-of-death {
                width: 50%;
            }
        }
    }
}
