//
//  Variables
//  _____________________________________________

$active-nav-indent: 42px !default;
$middle-bar__action__color: $text__color;
$middle-bar__action__color-hover: $text__color;
$middle-bar__action__color-active: $text__color;
$z-index__overlay: 14 !default;
$z-index__off-canvas-menu: 99 !default;
$theme-icon-color-regular: #333;

@mixin off-canvas-menu-item($item: a) {
    @include lib-list-reset-styles();

    li {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
    }

    #{$item} {
        @include lib-css(text-decoration, $navigation-level0-item__text-decoration);
        display: flex;
        font-weight: $font-weight__regular;
        padding: $navigation-level0-item__padding;
    }

    [aria-hidden=true] {
        display: none;
    }

    [data-toggle=dropdown] {
        @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-size: 14px,
            $_icon-font-position: after,
            $_icon-font-display: block
        );
        position: relative;

        &::after {
            position: absolute;
            right: 17px;
            top: 12px;
            pointer-events: none;
        }

        &.active {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-up,
                $_icon-font-position: after
            );
        }
    }
}

.panel.header {
    .topbar__list,
    .switcher {
        display: inline-block;
    }

    .links {
        display: none;
    }
}

.nav-toggle {
    @include lib-icon-font(
        $_icon-font-content: $icon-menu,
        $_icon-font-size: 28px,
        $_icon-font-color: $header-icons-color,
        $_icon-font-color-hover: $header-icons-color-hover
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    left: 15px;
    position: absolute;
    top: 15px;
    z-index: 14;
}

.nav-toggle-mobile {
    @include lib-icon-font(
        $_icon-font-content: $icon-remove,
        $_icon-font-size: 16px,
        $_icon-font-color: $header-icons-color,
        $_icon-font-color-hover: $header-icons-color-hover
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    position: absolute;
    top: 10px;
    right: 15px;
}

@include max-screen($screen__l) {
    .nav-sections-item-content > .navigation {
        width: 100%;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .customer-name,
    .customer-welcome + .authorization-link {
        display: none;
    }

    .navigation {
        padding: 0;

        .submenu {
            padding-left: 5px;
        }

        .parent {
            .level-top {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content: $icon-down,
                    $_icon-font-size: 42px,
                    $_icon-font-position: after,
                    $_icon-font-display: block
                );

                &:after {
                    position: absolute;
                    right: 17px;
                    top: 12px;
                    @include lib-font-size(14);
                }

                &.ui-state-active {
                    @include lib-icon-font-symbol(
                        $_icon-font-content: $icon-up,
                        $_icon-font-position: after
                    );
                }
            }
        }
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        @include lib-css(transition, left 0.3s, 1);
        height: 100%;
        left: -80%;
        left: calc(-1 * (100% - #{$active-nav-indent}));
        overflow: auto;
        position: fixed;
        top: 0;
        padding-top: 30px;
        width: 80%;
        width: calc(100% - #{$active-nav-indent});

        .section-item-content:not(#store\.menu) > ul {
            @include off-canvas-menu-item();
            padding-left: 15px;

            li {
                &.greet.welcome {
                    display: none;
                    border-top: 1px solid $color-gray82;
                    border-bottom: 1px solid $color-gray82;
                    font-weight: $font-weight__regular;
                    padding: $navigation-level0-item__padding;
                }
            }

            .header.links {
                border: 0;
                list-style: none;
                padding-left: 0;
            }

            a {
                color: $brand__primary__color;
            }
        }

        .switcher {
            font-size: 1.6rem;
            margin: 0;

            .label {
                display: block;
                margin-bottom: $indent__xs;
            }
        }

        .switcher-dropdown {
            @include lib-list-reset-styles();
        }

        .section-item-content:not(#store\.menu) > ul {
            @include lib-list-reset-styles();

            li {
                font-size: 1.6rem;
                margin: 0;
                border-bottom: 1px solid #d1d1d1;

                &.greet.welcome {
                    display: none;
                    border-top: 1px solid $color-gray82;
                    border-bottom: 1px solid $color-gray82;
                    font-weight: $font-weight__regular;
                    padding: $navigation-level0-item__padding;
                }

                &.customer-welcome {
                    .customer-name {
                        display: none;
                    }
                }
            }

            a,
            a:hover {
                @include lib-css(color, $navigation-level0-item__color);
                @include lib-css(text-decoration, $navigation-level0-item__text-decoration);
                display: block;
                font-weight: $font-weight__regular;
                padding: $navigation-level0-item__padding;
            }

            .header.links {
                border: 0;
                list-style: none;
                padding-left: 0;
            }
        }
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            @include lib-css(transition, left 0.3s, 1);
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .page-wrapper {
            left: 80%;
            left: calc(100% - #{$active-nav-indent});
        }

        .nav-sections {
            $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);

            @include lib-css(box-shadow, $_shadow, 1);
            left: 0;
            z-index: 99;
            background: $color-white;
        }

        .nav-toggle {
            &:after {
                background: rgba(0, 0, 0, $overlay__opacity);
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }

    .nav-sections-items {
        @include lib-clearfix();
        position: relative;
        z-index: 1;
    }

    .nav-sections-item-title {
        box-sizing: border-box;
        padding: 10px 15px;
        font-size: 17px;

        .nav-sections-item-switch {
            font-weight: 500;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .nav-sections-item-content {
        box-sizing: border-box;
        padding: 0 0 40px 15px;
    }

    @include lib-main-navigation();

    .switcher-language {
        @include off-canvas-menu-item($item: 'a, strong');
        color: $color-gray34;

        ul {
            @include lib-list-reset-styles();
        }
    }
}

.view-urnwinkel_be {
    &:before {
        content: '';
        display: flex;
        margin-right: 5px;
        width: 20px;
        height: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../images/belgium.png');
    }
}

.view-urnwinkel_nl {
    &:before {
        content: '';
        display: flex;
        margin-right: 5px;
        width: 20px;
        height: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../images/netherlands.png');
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .nav-toggle-mobile {
        display: none !important;
    }

    .header.panel > .header.links > .customer-welcome + .authorization-link {
        display: none;
    }

    .nav-toggle {
        display: none;
    }

    .nav-sections {
        @include lib-vendor-prefix-flex-shrink(0);
        @include lib-vendor-prefix-flex-basis(auto);
        @include lib-css(background, $navigation-desktop__background);
        margin-top: 30px;
    }

    .nav-sections-item-content {
        display: block !important;
    }

    .nav-sections-item-content > * {
        display: none;
    }

    .nav-sections-item-content {
        > .navigation {
            display: flex;
            justify-content: flex-end;
            padding: 0;
        }
    }

    @include lib-main-navigation-desktop();

    .panel.header {
        .topbar__list,
        .switcher {
            display: inline-block;
        }

        .links {
            display: none;
        }
    }
}

@media only screen and (max-width: 925px) and (min-width: 850px) {
    .navigation .level0 > .level-top {
        font-size: 15px;
    }
}
