.autocomplete-wrapper {
    position: absolute;
    background-color: #fff;
    border: 1px solid #c2c2c2;
    box-sizing: border-box;
    margin-right: 15px;
    right: 0;
    bottom: 1px;
    transform: translateY(100%);
    width: 500px;
    display: none;
    z-index: 30;

    &.active {
        display: block;
    }

    .item-wrapper {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin: 0;
            padding: 10px;

            &:not(:last-child) {
                border-bottom: 1px solid #f6f6f6;
            }

            &:hover {
                background-color: #f8f8f8;
            }
        }
    }

    .autocomplete-item {
        display: flex;
        align-items: center;
        gap: 10px;

        &:hover {
            text-decoration: none;
        }

        .image {
            img {
                width: 50px;
                min-width: 50px;
            }
        }

        mark {
            color: $primary-color;
            font-weight: bold;
            background: none;
        }

        .meta {
            width: 100%;
        }

        .title {
            color: #333;
            margin-bottom: .5rem;
            line-height: 1;
        }

        .description {
            font-size: 1.2rem;
            color: #999;
            height: 3rem;
            line-height: 1.5rem;
            overflow: hidden;
            margin-bottom: .5rem;
        }

        .price {
            font-weight: 600;
            color: #333;
        }
    }

    .item-count {
        background: #efefef;
        padding: 8px 10px;
        text-align: left;
        font-size: 1.2rem;
        line-height: 1.2rem;
        color: #999;
        text-transform: uppercase;
        font-weight: 700;

        &.show-all-results {
            cursor: pointer;
        }
    }
}

@include max-screen($screen__m) {
    .autocomplete-wrapper {
        width: 100%;
        margin-right: 0;
        bottom: 10px;
    }
}
