.urnwinkel_nl,
.urnengeschaft_de,
.urnes-funeraires,
.urnesfunerarias_es,
.legendurn_global,
.urnbutiken_se,
.urnesfuneraires_fr,
.urnwinkel_be,
.legendurn_uk,
.legendurn_ie,
.legendurn_no,
.urnengeschaft_ch,
.urnengeschaft_at,
.urnesfuneraires_be,
.urnes_funeraires_global,
.urnas_funerarias_overige_landen {
    .banner-wrapper {
        .content-wrapper {
            background-image: url('../images/slider-bg.png');
            background-position: 81%;
        }
    }
}

.banner-wrapper {
    background: #e4eff5;

    .content-wrapper {
        padding: 80px 0;
        background-image: url('../images/banner-new.jpeg');
        background-repeat: no-repeat;
        background-position: right;
        
        .action {
            @include lib-icon-font(
                $_icon-font-content: $icon-next,
                $_icon-font-size: 11px,
                $_icon-font-color: $color-white,
                $_icon-font-position: after
            );
            display: flex !important;
            align-items: center;
            text-transform: uppercase;
            font-size: 14px;
            margin-top: 30px;
            padding: 10px 20px 12px 20px;
            border-radius: 3px;
            font-weight: 500;
            position: relative;
            width: fit-content;
            
            &:hover {
                text-decoration: none;
                border: 0;
            }
            
            &:after {
                padding-left: 10px;
                font-weight: 600 !important;
            }
        }
        
        h2 {
            font-size: 60px;
            font-weight: 400;
            line-height: 1;
            margin: 0 0 20px 0;
            color: $h2__font-color;
        }
        
        p {
            font-size: 29px;
            color: $paragraph-color-light;
            font-weight: 300;
            margin: 0;
            
        }
    }
}

@include max-screen($screen__m) {
    .banner-wrapper {
        .content-wrapper {
            display: flex;
            justify-content: center;
            background-size: cover;
            background-position: bottom right 42%;
            text-align: center;
            padding: 0;
        
            h2 {
                font-size: 36px;
            }
        
            p {
                font-size: 22px;
                color: $paragraph-dark-color;
            }
    
            .action {
                margin: 10px auto;
            }
    
            .responisve-padding {
                padding: 45px 0;
            }
        }
    }
    
    .urnwinkel_nl,
    .urnengeschaft_de,
    .urnes-funeraires,
    .urnesfunerarias_es,
    .legendurn_global,
    .urnbutiken_se,
    .urnesfuneraires_fr,
    .urnwinkel_be,
    .legendurn_uk,
    .legendurn_ie,
    .legendurn_no,
    .urnengeschaft_ch,
    .urnengeschaft_at,
    .urnesfuneraires_be,
    .urnes_funeraires_global,
    .urnas_funerarias_overige_landen {
        .banner-wrapper .content-wrapper {
            padding: 0;
            background-size: cover;
        
            h2 {
                font-size: 36px;
            }
        
            p {
                font-size: 22px;
                color: $paragraph-dark-color;
            }
        
            .action {
                margin-top: 10px;
            }
        }
    
        .content-wrapper {
            display: flex;
            justify-content: center;
            background-size: contain;
            text-align: center;
        
            .action {
                margin: 10px auto;
            }
        }
    
        .responisve-padding {
            padding: 45px 0;
        }
    }
    
}

@include max-screen($screen__xxs) {
    .banner-wrapper {
        padding: 40px 0;
        background-image: none;
    }
}
