.swiper-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    h2 {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.swiper-wrapper-nav {
    position: relative;
}

.swiper-nav {
    display: none;
    
    &__button {
        @include lib-icon-text-hide();
        @include lib-icon-font(
            $_icon-font-content: false,
            $_icon-font-color: $brand__primary__color,
            $_icon-font-size: 18px
        );
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        width: 35px;
        cursor: pointer;
        background: white;
        border: 1px solid #CED0CF;
        z-index: 8;
        
        &:before,
        &:after {
            font-weight: $font-weight__bold
        }

        &--prev {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-prev
            );
            position: absolute;
            transform: translateY(50%);
            bottom: 50%;
        }

        &--next {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-prev
            );
            position: absolute;
            transform: rotate(-180deg) translateY(50%);
            top: 50%;
            right: 0;
        }
    }

    .swiper-button-disabled {
        cursor: auto;
        pointer-events: none;
        opacity: 0.5;
        
        &:before,
        &:after {
            color: #B7B7B7;
        }
    }
}

@include max-screen($screen__m) {
    .swiper-nav {
        display: flex;
    }
}
