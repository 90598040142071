.cta-link {
    @include lib-icon-font(
        $_icon-font-content: $icon-next,
        $_icon-font-size: 9px,
        $_icon-font-color: $brand__primary__color
    );
    color: $brand__primary__color;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    padding-left: 15px;

    &:hover {
        text-decoration: underline;

        &:before {
            padding-right: 5px;
        }
    }

    &::before {
        position: absolute;
        left: 0;
        top: 6px;
        font-weight: bold;
    }
}

.promotion-block-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 60px;

    ul {
        padding: 0;
        margin-bottom: 15px;
    }

    li {
        list-style: none;
    }

    h2,
    h3,
    h4 {
        font-size: 36px;
        color: $paragraph-dark-color;
        font-weight: 400;
        margin: 0;
        line-height: 1;
        padding-bottom: 20px;
    }

    p {
        font-size: 18px;
        color: $paragraph-color-light;
        line-height: 1.3;
        margin-bottom: 20px;
        font-weight: 300;
    }

    a {
        @extend .cta-link;
    }

    &__left,
    &__right {
        display: flex;
        flex-direction: row;
    }

    &__left {
        padding-right: 50px;
    }

    p img {
        margin: 0;
    }
}

.custom-container {
    border-top: 1px solid $border-color;

    &__left {
        display: flex;
        justify-content: space-between;

        img {
            align-self: flex-end;
        }
    }

    &__right {
        padding-right: 20%;
    }
}

.promotional-block {
    padding: 0 15px;

    .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        ul {
            padding: 0;
            margin-bottom: 15px;
        }

        li {
            list-style: none;
        }

        @include max-screen($screen__l) {
            padding-bottom: 20px;
        }
    }

    h2, h3, h4 {
        font-size: 36px;
        color: $paragraph-dark-color;
        font-weight: 400;
        margin: 0;
        line-height: 1;
        padding-bottom: 20px;
    }

    p {
        font-size: 18px;
        color: $paragraph-color-light;
        line-height: 22px;
        margin-bottom: 25px;
        font-weight: 300;
    }

    a {
        @extend .cta-link;
    }
}

@include max-screen($screen__m) {
    .promotion-block-wrapper {
        flex-direction: column;
        padding: 0;

        a {
            font-size: 13px;
            line-height: 26px;

            &::before {
                top: 2px;
            }
        }

        h2 {
            font-size: 28px;
        }

        &__left,
        &__right {
            width: auto;
            padding-top: 30px;
            position: relative;

            .content {
                width: 50%;
            }

            img {
                position: absolute;
                bottom: 0;
                right: 0;
                max-width: 50%;
            }
        }

        &__right {
            display: block;
        }

        &__left {
            padding-right: 0;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: -20px;
                width: 100vw;
                height: 1px;
                background: #e6e6e6;
            }

            .content {
                width: 100%;
            }
        }

        &__wrapper {
            padding: 20px;
            display: block;
        }
    }

    .custom-container {
        flex-direction: column;
    }

    .custom-container__right {
        width: 100%;
        padding: 0;
    }

    .custom-container__left {
        display: none;
    }

    .cms-home .custom-container .page-main {
        padding: 0 16px;
    }
}

@include max-screen($screen__l) {
    .promotion-block-wrapper {
        padding-top: 20px;

        &__left {
            padding-bottom: 20px;
        }
    }
}

@media (max-width: 1025px) and (min-width: 768px) {
    .promotion-block-wrapper {
        flex-direction: column;

        &__left,
        &__right {
            width: auto;
        }

        &__left {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: -20px;
                width: 100%;
                height: 1px;
                background: #e6e6e6;
            }
        }
    }
}

@media only screen and (max-width: 850px) and (min-width: 640px) {
    .promotion-block-wrapper {
        &__right {
            padding-top: 90px;
        }

        &__left {
            padding: 20px 0;
        }
    }
}
