.checkout-agreements-block {
    .checkout-agreements {
        margin-bottom: $indent__base;
    }
    
    .action-show {
        @extend .abs-action-button-as-link;
        vertical-align: baseline;
    }
    
    .fieldset>.field.choice .label:after, .fieldset>.field.choice .label:before, .fieldset>.fields>.field.choice .label:after, .fieldset>.fields>.field.choice .label:before {
        display: none;
    }
    
    .fieldset>.field.choice .label, .fieldset>.fields>.field.choice .label {
        padding-left: 0;
    }
}

//  Checkout Agreements in popup
.checkout-agreements-items {
    @extend .abs-reset-list;
    padding-bottom: $indent__l;
    
    .checkout-agreements-item {
        margin-bottom: $indent__base;
    }
    
    .checkout-agreements-item-title {
        @extend .abs-checkout-title;
        border-bottom: 0;
    }
}

//  Checkout Agreements
.checkout-agreement-item-content {
    overflow: auto;
}
