.footer-top-wrapper {
    color: $color-white;
    background-color: $brand__primary__color;
    margin-top: 20px;
    overflow: hidden;

    &__first-row {
        position: relative;
        margin-right: 30px;

        img {
            margin-top: -10px;
            margin-bottom: -2px;
            position: relative;
            z-index: 1;
            top: 3px;

            @include max-screen($screen__l) {
                display: none;
            }
        }

        &:after {
            background-image: url("../images/footer-bg.png");
            background-repeat: no-repeat;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            width: 280px;
            height: 119px;
            position: absolute;
            content: '';
            z-index: 0;

            @include max-screen($screen__l) {
                display: none;
            }
        }

        @include max-screen($screen__m) {
            display: none;
        }
    }

    .wrapper {
        display: flex;
        align-items: center;
        @include max-screen($screen__m) {
            display: block;
            text-align: center;
        }
    }

    .shop-info-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @include max-screen($screen__m) {
            display: block;
        }
    }

    &__row {
        margin-right: 110px;
        z-index: 2;

        a {
            color: $color-white;

            &:hover {
                text-decoration: none;
            }
        }

        &:last-child {
            margin: 0;
        }

        @include max-screen($screen__m) {
            margin-right: 0;
            margin-bottom: 25px;
        }
    }

    h2 {
        font-size: 20px;
        font-weight: 500;
    }

    h3 {
        font-size: 18px;
        font-weight: 300;
    }

    h4 {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        color: $color-white;
        padding-bottom: 5px;
    }

    span {
        font-size: 14px;
        font-weight: 300;
    }

    h2, h3, span {
        color: $color-white;
        margin: 0;
        padding: 0;
    }

    @include max-screen($screen__m) {
        margin-bottom: 40px;
    }
}

.catalog-product-view {
    .footer-top-wrapper {
        margin-top: 0;
    }
}

@include max-screen($screen__l) {
    .footer-top-wrapper {
        padding: 20px 0;

        h2,
        h4 {
            font-size: 16px;
            padding-bottom: 5px;
        }

        h3,
        span {
            font-size: 14px;
        }
    }
}

@include max-screen($screen__m) {
    .footer-top-wrapper {
        padding: 0;

        .shop-info-wrapper {
            padding: 15px 0;
        }

        &__row {
            margin: 0;
            padding: 15px 0;
        }

        h2 {
            font-size: 24px;
            line-height: 28px;
        }

        h3 {
            font-size: 18px;
            line-height: 28px;
            margin: 0;
            padding-bottom: 0;
        }
    }
}
