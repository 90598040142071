//
//  Icons variables
//  _____________________________________________

$icon__position            : before !default;
$icon__text-hide           : false !default;
$icon__height              : 26px !default;
$icon__width               : 26px !default;
$icon__margin              : 0px !default;
$icon__vertical-align      : middle !default;

$icon-image__position-x    : 0 !default;
$icon-image__position-y    : 0 !default;

$icon-sprite__position-x   : 0 !default;
$icon-sprite__position-y   : 0 !default;
$icon-sprite__grid         : 26px !default;

$icon-font                 : $icons__font-name !default;
$icon-font__position       : $icon__position !default;
$icon-font__text-hide      : $icon__text-hide !default;
$icon-font__margin         : $icon__margin !default;
$icon-font__size           : inherit !default;
$icon-font__line-height    : $icon-font__size !default;
$icon-font__color          : inherit !default;
$icon-font__color-hover    : false !default;
$icon-font__color-active   : false !default;
$icon-font__color-faded    : $theme-color-light-gray1 !default;
$icon-font__vertical-align : $icon__vertical-align !default;
$icon-font__display        : inline-block !default;

$icon-calendar__font-size  : 40px !default;

//
//  Variables for icons-blank-theme
//  ---------------------------------------------

// ToDo UI                 : move it to blank theme variables

$icon-whatsapp-brands: "\e909";
$icon-phone: "\e907";
$icon-account: "\e908";
$icon-plus-solid: "\e906";
$icon-facebook-f-brands: "\e900";
$icon-mobile-alt-solid: "\e901";
$icon-pinterest-p-brands: "\e902";
$icon-shopping-basket-solid: "\e903";
$icon-twitter-brands: "\e904";
$icon-youtube-brands: "\e905";
$icon-private: "\e629";
$icon-gift-registry: "\e62b";
$icon-present: "\e62a";
$icon-star-empty: "\e625";
$icon-download: "\e626";
$icon-undo: "\e628";
$icon-print: "\e624";
$icon-help: "\e623";
$icon-success: "\e60e";
$icon-error: "\e61f";
$icon-edit: "\e601";
$icon-wishlist-full: "\e600";
$icon-warning: "\e602";
$icon-update: "\e603";
$icon-trash: "\e604";
$icon-star: "\e605";
$icon-settings: "\e606";
$icon-pointer-down: "\e607";
$icon-next: "\e608";
$icon-menu: "\e609";
$icon-location: "\e60a";
$icon-list: "\e60b";
$icon-info: "\e60c";
$icon-grid: "\e60d";
$icon-minus: "\e60f";
$icon-checkmark: "\e610";
$icon-cart: "\e611";
$icon-calendar: "\e612";
$icon-arrow-up: "\e613";
$icon-arrow-down: "\e614";
$icon-search: "\e615";
$icon-remove: "\e616";
$icon-prev: "\e617";
$icon-pointer-up: "\e618";
$icon-pointer-right: "\e619";
$icon-pointer-left: "\e61a";
$icon-flag: "\e61b";
$icon-expand: "\e61c";
$icon-envelope: "\e61d";
$icon-compare-full: "\e61e";
$icon-comment: "\e620";
$icon-up: "\e621";
$icon-down: "\e622";
$icon-wishlist-empty       : '\e601' !default;
$icon-phone-solid: "\e90a";
