//
//  Pager variables
//  _____________________________________________

$pager-label__display                        : none !default;
$pager-reset-spaces                          : true !default;  // Reset spaces between inline-block elements

$pager__font-size                            : $font-size__s !default;
$pager__font-weight                          : $font-weight__regular !default;
$pager__line-height                          : 28px !default;

$pager-item__display                         : inline-block !default;
$pager-item__margin                          : 0 3px !default;
$pager-item__padding                         : 0 11px !default;
$pager-border__color                         : $color-gray82 !default;
$pager-border__radius                        : 3px !default;

$pager-actions__padding                      : 0 !default;

//  Pager current page
$pager-current__font-weight                  : $font-weight__regular !default;
$pager-current__color                        : $color-white !default;
$pager-current__border-style                 : solid !default;
$pager-current__border-width                 : 1px !default;
$pager-current__border-color                 : $brand__primary__color !default;
$pager-current__background                   : $brand__primary__color !default;
$pager-current__gradient                     : false !default;
$pager-current__gradient-direction           : false !default;
$pager-current__gradient-color-start         : false !default;
$pager-current__gradient-color-end           : false !default;

//  Pager link page
$pager__gradient                             : false !default;
$pager__gradient-direction                   : false !default;

//  Pager link default
$pager__color                                : $primary__color__lighter !default;
$pager__border-style                         : solid !default;
$pager__border-width                         : 1px !default;
$pager__border-color                         : $pager-border__color !default;
$pager__border-radius                        : $pager-border__radius !default;
$pager__text-decoration                      : none !default;
$pager__background                           : false !default;
$pager__gradient-color-start                 : false !default;
$pager__gradient-color-end                   : false !default;

//  Pager link visited
$pager__visited__color                       : $primary__color__lighter !default;
$pager__visited__border-style                : solid !default;
$pager__visited__border-width                : false !default;
$pager__visited__border-color                : false !default;
$pager__visited__background                  : false !default;
$pager__visited__gradient-color-start        : false !default;
$pager__visited__gradient-color-end          : false !default;

//  Pager link hover
$pager__hover__color                         : $primary__color__light !default;
$pager__hover__border-style                  : solid !default;
$pager__hover__border-width                  : false !default;
$pager__hover__border-color                  : false !default;
$pager__hover__text-decoration               : none !default;
$pager__hover__background                    : false !default;
$pager__hover__gradient-color-start          : false !default;
$pager__hover__gradient-color-end            : false !default;

//  Pager link active
$pager__active__color                        : $primary__color !default;
$pager__active__border-style                 : solid !default;
$pager__active__border-width                 : 1px !default;
$pager__active__border-color                 : false !default;
$pager__active__background                   : false !default;
$pager__active__gradient-color-start         : false !default;
$pager__active__gradient-color-end           : false !default;

//  Pager link.action
$pager-icon__use                             : false !default;
$pager-icon__previous-content                : $icon-pointer-left !default;
$pager-icon__next-content                    : $icon-pointer-right !default;
$pager-icon__text-hide                       : false !default;
$pager-icon__position                        : before !default;
$pager-icon__font                            : $icon-font !default;
$pager-icon__font-margin                     : 0 10px !default;
$pager-icon__font-vertical-align             : top !default;
$pager-icon__font-size                       : 11px !default;
$pager-icon__font-line-height                : $icon-font__line-height !default;

//  Pager link.action gradient               : element has a gradient background
$pager-action__gradient                      : false !default; // [true|false]
$pager-action__gradient-direction            : false !default; // [true|false]

//  Pager link.action default
$pager-action__color                         : $text__color__muted !default;
$pager-action__border-style                  : solid !default;
$pager-action__border-width                  : 0 !default;
$pager-action__border-color                  : $border-color__base !default;
$pager-action__text-decoration               : underline !default;
$pager-action__background                    : $pager__background !default;
$pager-action__gradient-color-start          : false !default;
$pager-action__gradient-color-end            : false !default;

//  Pager link.action visited
$pager-action__visited__color                : $pager-action__color !default;
$pager-action__visited__border-style         : solid !default;
$pager-action__visited__border-width         : false !default;
$pager-action__visited__border-color         : false !default;
$pager-action__visited__background           : false !default;
$pager-action__visited__gradient-color-start : false !default;
$pager-action__visited__gradient-color-end   : false !default;

//  Pager link.action hover
$pager-action__hover__color                  : $primary__color__light !default;
$pager-action__hover__border-style           : solid !default;
$pager-action__hover__border-width           : false !default;
$pager-action__hover__border-color           : false !default;
$pager-action__hover__background             : false !default;
$pager-action__hover__text-decoration        : $pager-action__text-decoration !default;
$pager-action__hover__gradient-color-start   : false !default;
$pager-action__hover__gradient-color-end     : false !default;

//  Pager link.action active
$pager-action__active__color                 : $pager-action__color !default;
$pager-action__active__border-style          : solid !default;
$pager-action__active__border-width          : false !default;
$pager-action__active__border-color          : false !default;
$pager-action__active__background            : false !default;
$pager-action__active__gradient-color-start  : false !default;
$pager-action__active__gradient-color-end    : false !default;
