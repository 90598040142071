$filter-title-background-color: $color-gray94;
$filter-link: $color-gray-darken4;
$filter-link-hover: darken($filter-link, 30%);
$filter-quantity: $color-gray46;

.trigger-filters {
    @include lib-button();
    @include lib-link-as-button();
    @include lib-css(border-radius, $button__border-radius);
    float: left;
    margin-right: 15px;
    margin-bottom: 10px;
    padding: 10px 20px;

    .products.wrapper ~ .toolbar & {
        display: none;
    }

    &:focus,
    &:active {
        color: $color-white;
    }
}

.subcategory-wrapper {
    .filter {
        &-options {
            &-title {
                @include lib-icon-font(
                    $_icon-font-content: $icon-plus-solid,
                    $_icon-font-size: 13px,
                    $_icon-font-position: after,
                    $_icon-font-display: block
                );

                &:after {
                    position: absolute;
                    right: 13px;
                    top: 12px;
                }

                &:hover {
                    @include lib-css(color, $filter-link-hover);
                }

                .active > & {
                    @include lib-icon-font-symbol(
                        $_icon-font-content: $icon-minus,
                        $_icon-font-position: after
                    );
                }
            }
        }
    }
}


.filter {
    &.block {
        margin-bottom: 0;
    }

    &-title {
        strong {
            $_shadow: inset 0 1px 0 0 $color-white, inset 0 -1px 0 0 rgba($border-color__base, 0.3);
            @include lib-css(background-color, $toolbar-element-background);
            @include lib-css(box-shadow, $_shadow);
            border: 1px solid $border-color__base;
            border-radius: 3px;
            font-weight: 400;
            left: 0;
            line-height: 16px;
            padding: 7px $indent__s;
            position: absolute;
            text-align: center;
            top: 0;
            z-index: 2;
            display: none;

            &[data-count]:after {
                @include lib-css(color, $color-white);
                background: $color-orange-red1;
                border-radius: 2px;
                content: attr(data-count);
                display: inline-block;
                font-size: 0.8em;
                line-height: 1;
                margin: 0 $indent__xs;
                min-width: 1em;
                padding: 2px;
            }

            &.disabled {
                opacity: 0.5;
            }
        }
    }

    .block-subtitle {
        border-bottom: $border-width__base solid $border-color__base;
        font-size: $font-size__base;
        font-weight: $font-weight__semibold;
        line-height: 1em;
        padding: $indent__s $indent__s $indent__base;
    }

    &-subtitle {
        display: none;
    }

    &-current {
        margin: 0;

        .items {
            padding: $indent__xs $indent__s;
        }

        .item {
            padding-left: 17px;
            position: relative;
            z-index: 1;
        }

        .filter &-subtitle {
            border: none;
            display: block;
            padding-bottom: $indent__s;
        }

        .action.remove {
            @extend .abs-remove-button-for-blocks;
            left: -2px;
            position: absolute;
            top: -1px;
        }
    }

    &-actions {
        margin-bottom: $indent__m;
        padding: 0 $indent__s;
    }

    &-label {
        font-weight: $font-weight__bold;

        &:after {
            content: ': ';
        }
    }

    &-value {
        @include lib-css(color, $filter-quantity);
    }

    &-options {
        display: none;
        margin: 0;

        &-item {
            border-bottom: $border-width__base solid $border-color__base;
            padding-bottom: $indent__s;

            &.active {
                .filter-options-title {
                    &:after {
                        content: $icon-minus;
                    }
                }

                .filter-options-content {
                    display: block;
                }
            }

            &.category {
                .filter-options-title {
                    &:after {
                        display: none;
                    }
                }

                .filter-options-content {
                    display: block;

                    .item {
                        a {
                            color: #333;
                            font-size: 15px;
                            font-weight: 500;
                        }
                    }
                }
            }

            .price-filter {
                display: flex;
                align-items: center;
                gap: 10px;

                button {
                    background-color: $primary-color;
                    color: #fff;
                    border: none;
                    height: 38px;

                    &:before {
                        @include icon($icon-next);
                    }
                }
            }
        }

        &-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            font-family: $font-family-name__base;
            font-weight: 500;
            margin: 0;
            overflow: hidden;
            padding: $indent__s $indent__s 0 0;
            position: relative;
            word-break: break-word;
            z-index: 1;
            font-size: 21px;

            &:after {
                @include icon($icon-expand);
                font-size: 13px;
            }

            strong {
                font-weight: 500;
            }
        }

        &-content {
            display: none;
            margin: 0;
            padding: $indent__s 0;

            .item {
                line-height: 1.5em;
                margin: $indent__s 0;
            }

            a.c-checkbox {
                @include lib-css(color, $filter-link);
                margin-left: -5px;
                margin-right: -5px;
                padding-left: 5px;
                padding-right: 7px;
                line-height: 1.3;

                &:hover {
                    text-decoration: none;
                }
            }

            .label {
                cursor: pointer;
            }

            .count {
                @include lib-css(color, $filter-quantity);
                font-weight: $font-weight__light;
                padding-left: 5px;
                padding-right: 5px;
                color: #a6a6a6;
                font-size: 13px;
            }

            .filter-count-label {
                @include abs-visually-hidden();
            }
        }
    }
}

.sidebar {
    dt {
        cursor: pointer;
        font-family: $font-family-name__base;
        font-weight: 500;
        margin: 0;
        overflow: hidden;
        padding: $indent__s $indent__s $indent__s 0;
        position: relative;
        word-break: break-all;
        z-index: 1;
        font-size: 21px;
    }

    .title {
        display: none;
    }
}

.mst-nav__slider-text {
    font-size: 16px;
    margin-bottom: 15px;
}

.ui-slider-handle {
    height: 14px;
    width: 14px;
    transform: translateY(-2px) translateX(-2px);
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    html {
        &.filter-active {
            .page-header {
                display: none;
            }

            .filter-options {
                display: block;
            }
        }
    }

    .filters {
        margin-bottom: 0;
    }

    .filter-options {
        position: fixed;
        background-color: #fff;
        width: 100%;
        top: 0;
        z-index: 10;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
        padding-top: 48px;
        padding-right: 15px;
        box-sizing: border-box;

        .close {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
            background-color: $primary-color;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;

            &:before {
                @include icon($icon-remove);
                color: #fff;
                font-size: 16px;
            }
        }
    }

    .filter {
        &.active {
            position: relative;
            visibility: visible;
            z-index: 99;

            .filter-options-item:last-child {
                margin-bottom: $indent__xl;
            }

            .filter-title {
                height: 48px;
                left: 0;
                position: fixed;
                right: 0;
                top: 0;
                z-index: 12;
                background: white;

                strong {
                    @include lib-icon-font(
                        $_icon-font-content: $icon-remove,
                        $_icon-font-color: $color-white,
                        $_icon-font-size: 16px,
                        $_icon-font-position: after
                    );
                    background: $brand__primary__color;
                    border: 0;
                    color: transparent;
                    left: auto;
                    right: 3px;
                    top: 10px;
                }
            }

            .filter-subtitle {
                @include lib-css(background, $toolbar-background);
                display: block;
                height: 50px;
                left: 0;
                line-height: 32px;
                position: fixed;
                right: 0;
                top: 0;
                z-index: 1;
                display: none;
            }

            .filter-options {
                @include lib-css(background, $color-white);
                bottom: 0;
                display: block;
                left: 0;
                overflow: scroll;
                position: fixed;
                right: 0;
                top: 47px;
                z-index: 10;
            }
        }
    }

    .filter {
        & &-subtitle {
            font-size: 20px;
            font-weight: $font-weight__light;
        }

        &-actions {
            margin-bottom: 0;
        }

        &-options-item {
            margin-left: 15px;
        }

        &-options-content {
            padding: 0;

            .price-filter {
                margin-top: 10px;
            }
        }
    }

    .filter .filter-current {
        border: solid $border-color__base;
        border-width: 1px 0;
        margin: $indent__xs (-$indent__s) 0;

        .items {
            display: none;
        }

        &-subtitle {
            position: relative;
            text-transform: uppercase;
            z-index: 1;

            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 13px,
                $_icon-font-position: before,
                $_icon-font-display: block
            );

            &:before {
                position: absolute;
                right: 10px;
                top: 10px;
            }

            &:after {
                @include lib-css(color, $text__color__muted);
                content: ' (' attr(data-count) ')';
                font-size: 0.9em;
            }
        }

        &.active {
            .block-subtitle {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: before
                );
            }

            .items {
                display: block;
            }

            & + .block-actions {
                display: block;
            }
        }

        & + .block-actions {
            display: none;
        }
    }

    .filter-no-options {
        .filter-title {
            &:before {
                background: rgba(255, 255, 255, 0.5);
                content: '';
                display: block;
                height: 40px;
                left: -15px;
                margin-top: -60px;
                position: relative;
                width: 100px;
                z-index: 99;
            }
        }

        .filter-content {
            margin-bottom: $indent__base;
        }
    }

    .page-with-filter {
        .columns {
            .sidebar-main {
                @include lib-vendor-prefix-order(0);
            }
        }
    }

    .trigger-filters {
        display: flex;
        align-items: center;
        padding: 0 20px;
        border-radius: 3px;
        line-height: 1;
        color: #b8b8b8;
        height: 32px;
        width: 100%;
    }

    .ui-slider-horizontal {
        margin-right: 25px;
    }

    .trigger-filters {
        &:active,
        &:focus {
            color: #b8b8b8;
        }
    }
}

.show-more {
    @include lib-icon-font(
        $_icon-font-content: $icon-plus-solid,
        $_icon-font-color: $text__color__muted,
        $_icon-font-size: 16px,
        $_icon-font-display: flex
    );

    &::before {
        padding-right: 10px;
        align-items: center;
    }
}

.show-less {
    @include lib-icon-font(
        $_icon-font-content: $icon-minus,
        $_icon-font-color: $text__color__muted,
        $_icon-font-size: 16px,
        $_icon-font-display: flex
    );

    &::before {
        padding-right: 10px;
        align-items: center;
    }
}

@include min-screen($screen__m) {
    .filter {
        &.block {
            margin-bottom: $indent__xl;
        }

        &-title {
            display: none;
        }

        &-content {
            .item {
                margin: $indent__s 0;
            }
        }

        &-actions {
            margin-bottom: $indent__l;
        }

        &.active &-options,
        &-options {
            background: transparent;
            clear: both;
            display: block;
            overflow: initial;
            position: static;
        }

        &-subtitle {
            display: none;
            position: static;
        }
    }

    .page-layout-1column {
        .toolbar-products {
            position: absolute;
            top: 0;
            width: 100%;
        }

        .products ~ .toolbar-products {
            position: static;
        }

        &.page-with-filter .column.main {
            padding-top: 45px;
            position: relative;
            z-index: 1;
        }

        .filter {
            &.block {
                border-top: 1px solid $border-color__base;
            }

            &-content {
                margin-top: $indent__s;
            }

            &-subtitle {
                display: none;
            }

            &-options {
                &-item {
                    border: 0;
                    display: inline-block;
                    margin-right: $indent__m;
                    position: relative;

                    &.active {
                        z-index: 2;

                        .filter-options-content {
                            visibility: visible;
                        }

                        &:hover {
                            z-index: 3;
                        }

                        &:after,
                        &:before {
                            @include lib-arrow(up, 8px, $color-black);
                            bottom: -1px;
                            content: '';
                            display: block;
                            left: $indent__xs;
                            position: absolute;
                            z-index: 3;
                        }

                        &:after {
                            @include lib-css(border-bottom-color, $color-white);
                            margin-top: 2px;
                            z-index: 4;
                        }
                    }
                }

                &-title {
                    padding: 0 $indent__base 0 0;

                    &:after {
                        right: 2px;
                        top: 3px;
                        z-index: 3;
                    }
                }

                &-content {
                    $_shadow: 0 3px 5px 0 rgba(50, 50, 50, 0.75);

                    @include lib-css(background, $color-white);
                    @include lib-css(box-shadow, $_shadow, 1);
                    border: 1px solid $border-color__base;
                    padding: $indent__xs 0;
                    position: absolute;
                    top: 100%;
                    visibility: hidden;
                    width: 180px;
                    z-index: 2;

                    .item {
                        margin: 0;
                        padding: $indent__xs;

                        a {
                            margin-left: 0;
                        }

                        &:hover {
                            background-color: $color-gray91;
                        }
                    }
                }
            }

            &-current {
                display: inline;
                line-height: 35px;

                &-subtitle {
                    @include lib-css(color, $text__color__muted);
                    display: inline;
                    font-size: $font-size__base;
                    font-weight: normal;
                    padding: 0;

                    &:after {
                        content: ':';
                    }
                }

                .item,
                .items {
                    display: inline;
                }

                .item {
                    margin-right: $indent__m;
                    white-space: nowrap;
                }

                .action.remove {
                    line-height: normal;
                }
            }

            &-actions {
                display: inline;
                white-space: nowrap;

                & ~ .filter-options {
                    margin-top: $indent__m;
                }
            }
        }
    }
}

.layered-filter-block-container {
    .items {
        a {
            color: #9b9b9b;
            font-weight: 200;
            display: flex;
            align-items: center;
        }
    }

    label {
        margin-left: 5px;
    }
}

.filter-options {
    a {
        color: $paragraph-dark-color;
        font-size: 15px;
        font-weight: 500;
    }
}

.ui-slider-handle {
    background: $brand__primary__color !important;
}
