.related,
.upsell {
    background: #eef3f5;
    padding: 20px 0 0 0;
    border-bottom: 1px solid darken(#eef3f5, 10%);

    #block-related-heading {
        text-align: center;
    }

    .block-actions {
        display: none;
    }

    .product-item .tocart {
        margin-top: 10px;
    }

    .blocks {
        margin-bottom: 0;
    }

    .products {
        display: flex;
        justify-content: center;
        margin: 0 !important;
    }

    .products-grid .product-item {
        margin-bottom: 5px;
    }

    .product-item-name {
        min-height: 90px;
    }

    .product-item .old-price .price {
        font-weight: 700;
    }

    .more-info {
        margin-right: 10px;
        font-size: 14px;
        color: #b8b8b8;
    }

    .title {
        text-align: center;
        font-size: 42px;
        font-weight: 500;
    }

    .product-item {
        margin-right: 20px !important;

        &[last-child] {
            margin-right: 0;
        }
    }

    .price-box {
        position: relative;

        .old-price {
            position: absolute;
            top: 0;

            span {
                font-size: 15px;
            }
        }
    }

    .product-image-wrapper {
        background: white;
    }

    .product-item-photo {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-white;
        z-index: 5;

        &::after {
            content: "";
            background: rgba(17, 87, 115, 0.07);
            width: 100%;
            height: 100%;
            z-index: 10;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

.block {
    .related-heading {
        margin-bottom: 20px;
    }
}

@include max-screen($screen__m) {
    .related,
    .upsell {
        .product-item {
            margin-bottom: 80px;
            margin-right: 0 !important;

            &:nth-last-child(-n+2) {
                margin-bottom: 0;
            }
        }

        .title {
            font-size: 22px;
        }
    }

    .related {
        .grid .product-image-container {
            display: block;
        }
    }
}
