$toolbar-mode-icon-font-size: 16px;
$toolbar-background: transparent;
$toolbar-element-background: $color-white;
$toolbar-element__color: $color-gray37;
$pager-action__background: $toolbar-element-background;
$pager-action__hover__background: darken($toolbar-element-background, 7%);
$pager__font-weight: $font-weight__bold;
$button__shadow-active: inset 0 1px 0 0 rgba($border-color__base, 0.8), inset 0 -1px 0 0 rgba($border-color__base, 0.3);

.page-products {
    .columns {
        position: relative;
        z-index: 1;
    }
}

.toolbar {
    @extend .abs-add-clearfix;
    margin-bottom: $indent__l;
    text-align: center;

    select {
        @include lib-css(background-color, $toolbar-element-background);
        @include lib-css(box-shadow, $button__shadow);
        border-radius: 3px;
    }

    &-amount {
        left: 0;
        line-height: $toolbar-mode-icon-font-size + 2;
        margin: 0;
        padding: 7px 0;
        text-align: left;
        top: 0;
        vertical-align: middle;

        .products.wrapper ~ .toolbar & {
            display: none;
        }
    }

    .page-with-filter & {
        &-amount {
            position: static;
        }
    }

    &-products {
        @include lib-css(background-color, $toolbar-background);

        .pages {
            display: none;

            .products.wrapper ~ & {
                display: block;
            }
        }
    }

    .pages {
        margin-bottom: $indent__m;
    }
}

.sorter {
    float: right;

    .products.wrapper ~ .toolbar & {
        display: none;
    }

    &-options {
        margin: 0 $indent__xs 0 7px;
        width: auto;
    }

    &-action {
        @include lib-icon-font(
                $icon-arrow-up,
            $_icon-font-size: 16px,
            $_icon-font-color: $header-icons-color,
            $_icon-font-color-hover: $header-icons-color-hover
        );
        @include lib-icon-text-hide();

        &.sort-desc:before {
            content: $icon-arrow-down;
        }
    }
}

 .modes {
    display: none;
}

.toolbar {
    .modes {
        a {
            &:hover {
                color: $brand__primary__color;
            }
        }
    }
}


.limiter {
    &-options {
        margin: 0 5px 0 7px;
        width: auto;
    }

    &-label {
        font-weight: 400;
    }

    .page-products .toolbar & {
        display: none;
    }

    .control {
        display: inline-block;
    }
}

//
//  Mobile (small)
//  _____________________________________________

@include max-screen($screen__xs) {
    .sorter-label {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .toolbar-products {
        margin-bottom: 0;
    }

    .limiter-options,
    .sorter-options {
        width: 100%;
        margin: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .columns {
            position: relative;
            z-index: 1;
        }
    }

    .toolbar-amount {
        display: block;
        float: left;
        position: static;
    }

    .toolbar {
        .products.wrapper ~ & .pages {
            float: right;
            margin-bottom: 0;
        }
    }

    .modes {
        display: inline-block;
        float: left;
        margin-right: $indent__base;

        .products.wrapper ~ .toolbar & {
            display: none;
        }
    }

    .modes-label {
        @extend .abs-visually-hidden-desktop;
    }

    .modes-mode {
        @include lib-css(color, $toolbar-element__color);
        float: left;
        font-weight: $font-weight__regular;
        line-height: 1;
        padding: 7px 10px;
        text-align: center;
        display: flex !important;

        .modes-label + & {
            border-radius: 3px 0 0 3px;
            display: flex;
        }

        &:last-child {
            border-radius: 0 3px 3px 0;
        }

        &.active {
            color: $brand__primary__color;

            &:before {
                color: $brand__primary__color;
            }
        }

        @include lib-icon-font(
                $icon-grid,
            $_icon-font-size: $toolbar-mode-icon-font-size,
            $_icon-font-text-hide: true,
            $_icon-font-color: $text__color__muted,
            $_icon-font-color-hover: $text__color__muted
        );
    }

    .sorter {
        .page-products & {
            position: static;
            display: flex;
            align-items: center;
        }

        .sorter-options {
            height: 43.5px;
        }
    }

    .mode-grid {
        &:hover {
            text-decoration: none;
        }
    }

    .mode-list {
        &:before {
            content: $icon-list;

        }
        &:hover {
            text-decoration: none;
        }
    }

    .limiter {
        float: right;
        .products.wrapper ~ .toolbar & {
            display: block;
        }
    }

    .trigger-filters {
        .toolbar-products & {
            display: none;
        }
    }
}

.label-grid, .label-list {
    padding-left: 10px;
    &:hover {
        text-decoration: none;
    }
}

.modes-mode {
    &:hover {
        &:before {
            color: $brand__primary__color;
        }
    }
}
