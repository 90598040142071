.customer-score-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    text-align: center;

    .trustpilot-image {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;

        img {
            max-width: 7%;
            margin-right: 5px;
        }
    }

    span {
        color: $paragraph-dark-color;
        font-size: 15px;
        margin: 30px 0;
        font-weight: 300;
        width: 100%;

        a {
            color: $brand__primary__color;
        }
    }
}

.cat-customer-score-wrapper {
    padding: 25px 0;
    border-bottom: 1px solid $border-color;
    border-top: 1px solid $border-color;
    text-align: center;

    .header-after-container & {
        margin-top: -20px;
        margin-bottom: 20px;
    }

    span {
        color: #9b9b9b;
        font-size: 15px;
        margin: 30px 0;
        font-weight: 300;
        width: 100%;

        a {
            color: $brand__primary__color;
        }
    }
}

@include max-screen($screen__m) {
    .customer-score-wrapper {
        .trustpilot-image {
            img {
                max-width: 25%;
            }
        }
    }
}
